import { Fragment, useState, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { BookOpenIcon } from "@heroicons/react/24/solid";
import {
  OrganizationSwitcher,
  SignOutButton,
  UserButton,
  useUser,
} from "@clerk/clerk-react";
import { Outlet, useLocation } from "react-router-dom";
import { useCurrentProject } from "../hooks";
import LinkWithQuery from "./LinkWithQuery";
import { BoltIcon, HandRaisedIcon } from "@heroicons/react/24/solid";
import Footer from "./Footer";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function useLocalNav() {
  const { pathname } = useLocation();
  const { project } = useCurrentProject();
  if (project === undefined) {
    return [];
  }

  const localNavigation = [
    { name: "Dashboard", href: `/${project.id}` },
    {
      name: "Conversations",
      href: `/${project.id}/conversations`,
    },
    { name: "Messages", href: `/${project.id}/messages/search` },
    { name: "Metrics", href: `/${project.id}/metrics` },
    { name: "Frames", href: `/${project.id}/frames` },
    { name: "Settings", href: `/${project.id}/settings` },
    { name: "Evaluation", href: `/${project.id}/testsuites` },
  ];
  return localNavigation.map((item) => ({
    ...item,
    current:
      pathname === item.href ||
      (pathname.startsWith(`${item.href}`) && item.href !== `/${project.id}`), // Exclude Dashboard
  }));
}

function LocalNav() {
  const localNavigation = useLocalNav();
  return (
    <div className="flex items-baseline overflow-y-auto sm:space-x-4">
      {localNavigation.map((item) => (
        <div
          className={classNames(
            item.current ? "border-b-2 border-emerald-600" : "",
            "p-1 pb-2"
          )}
          key={item.name}
        >
          <LinkWithQuery
            to={item.href}
            key={item.name}
            className="whitespace-nowrap rounded-md px-2 py-1 text-sm font-medium text-gray-900 hover:bg-gray-100"
          >
            {item.name}
          </LinkWithQuery>
        </div>
      ))}
    </div>
  );
}

function AdminDashboardButton() {
  const [url, setUrl] = useState<string>("");
  const { user } = useUser();
  const isAdmin = user?.publicMetadata?.sys_admin;

  useEffect(() => {
    const getCredentials = async () => {
      const platformToken = await (window as any).Clerk.session.getToken({
        template: "api_botbrains_io",
      });
      setUrl(
        `${
          import.meta.env.VITE_DASHBOARD_URL
        }?embed=true&token=${platformToken}`
      );
    };
    getCredentials();
  });

  if (!isAdmin) return <></>;
  return (
    <a href={url} target="_parent">
      <button className="flex items-center justify-between rounded-full p-1 font-medium text-gray-900 hover:bg-gray-100">
        <BoltIcon className="h-6 w-6 text-yellow-400" />
        <span className="ml-1 mr-2">Admin</span>
      </button>
    </a>
  );
}

export default function Navigation() {
  const { project } = useCurrentProject();
  const { pathname } = useLocation();
  const navigation = [{ name: "Projects", href: "/" }].map((item) => ({
    ...item,
    current: pathname == item.href,
  }));

  return (
    <div className="flex min-h-screen flex-col">
      <div>
        <Disclosure as="nav" className="border-b border-gray-200 bg-white">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 justify-between">
                  <div className="flex items-center gap-3">
                    {/* Logo */}
                    <LinkWithQuery to="/">
                      <div className="flex flex-shrink-0 items-center">
                        <img
                          className="block h-10 w-auto lg:hidden"
                          src="/botbrains-square.svg"
                          alt="botBrains Logo"
                        />
                        <img
                          className="hidden h-6 w-auto lg:block"
                          src="/botbrains-logo.png"
                          alt="botBrains Logo"
                        />
                      </div>
                    </LinkWithQuery>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18 18 6M6 6l12 12"
                      />
                    </svg>
                    {/* Fix Alignment */}
                    <div className="pt-2">
                      {/* NOTE(liamvdv): afterSelectOrganizationUrl={"/"} will result in a fresh load of the page, clearing Redux on Org Change. */}
                      <OrganizationSwitcher
                        hidePersonal={true}
                        afterSelectOrganizationUrl={"/"}
                      />
                    </div>
                    {/* Project */}
                    {/* Display only on at least sm: screens in bar, else below */}
                    {project && (
                      <>
                        <span className="hidden text-[1.375rem] text-gray-400/80 sm:block">
                          /
                        </span>
                        <LinkWithQuery
                          to={`/${project.id}`}
                          className="hidden sm:block"
                        >
                          <span className="rounded p-1 text-[1.1rem] font-medium text-gray-700 hover:bg-gray-100">
                            {project.name}
                          </span>
                        </LinkWithQuery>
                      </>
                    )}
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:items-center text-sm">
                    <Menu as="div" className="relative ml-3">
                      <div className="flex flex-row items-center justify-between gap-1">
                        <AdminDashboardButton />

                        <a title="Get Help">
                          <button
                            className="flex items-center justify-center rounded-full p-1 hover:bg-gray-100"
                            onClick={() =>
                              window.$crisp.push(["do", "chat:open"])
                            }
                          >
                            <HandRaisedIcon className="h-6 w-6 text-yellow-400" />
                            <span className="ml-1 mr-2 font-medium">Help</span>
                          </button>
                        </a>

                        <a
                          title="Documentation"
                          href="https://botbrains-docs.notion.site/"
                          target="_blank"
                        >
                          <button className="flex items-center justify-center rounded-full p-1 hover:bg-gray-100">
                            <BookOpenIcon className="h-6 w-6 text-yellow-400" />
                            <span className="ml-1 mr-2 font-medium">Docs</span>
                          </button>
                        </a>

                        <UserButton />
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {/* {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                              href={item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                              >
                              {item.name}
                              </a>
                              )}
                              </Menu.Item>
                            ))} */}
                          <Menu.Item>
                            <UserButton />
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>

                  {/* Mobile menu button */}
                  <div className="-mr-2 flex items-center sm:hidden">
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
                {/* Always display */}
                {project !== undefined && <LocalNav />}
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="space-y-1 pb-3 pt-2">
                  {navigation.map((item) => (
                    <LinkWithQuery
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current
                          ? "border-indigo-500 bg-indigo-50 text-indigo-700"
                          : "border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800",
                        "block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
                      )}
                    >
                      {item.name}
                    </LinkWithQuery>
                  ))}
                  <AdminDashboardButton />
                </div>
                <div className="border-t border-gray-200 pb-3 pt-4">
                  <div className="my-3 space-y-4">
                    <div className="mx-4 flex flex-row items-center justify-between">
                      <span className="font-medium text-gray-600">
                        Account:
                      </span>
                      <UserButton showName={true} />
                    </div>
                    <div className="mx-4 font-medium text-gray-600">
                      <SignOutButton />
                    </div>
                    {/* {[{name: "l", href: "#"}].map((item) => (
                      <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                      >
                      {item.name} HIHIH
                      </Disclosure.Button>
                    ))} */}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        {/* Display here only on at below sm: (mobile) devices, else above */}
        {/* Project */}
        {project && (
          <div className="block sm:hidden">
            <div className="border-gray border-b">
              <div className="mx-4 flex flex-row justify-between gap-6 text-sm text-gray-700">
                <span>{project.name}</span>
                <span>ID {project.id}</span>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex-grow">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}
