import { Tag } from "@/client/types.gen";
import { findClosestLanguageMatch } from "@/ietf-bcp-47";
import { useUser } from "@clerk/clerk-react";

interface TagStyleConfig {
  colors:
    | {
        circle: string;
        text?: string;
        border?: string;
        background?: string;
      }
    | {
        [key: string]: {
          circle: string;
          text?: string;
          border?: string;
          background?: string;
        };
      }
    | ((value: string) => {
        circle: string;
        text?: string;
        border?: string;
        background?: string;
      });

  label?: string | ((value: string) => string);
  href?: (value: string) => string;
}

const TAG_CONFIG: Record<string, TagStyleConfig> = {
  "api:tracing_url": {
    colors: { circle: "fill-blue-500" },
    label: "Tracing URL",
    href: (value) => value,
  },
  "chat:rating": {
    colors: { circle: "fill-green-500" },
    label: (value: string) =>
      `User rated ${value === "thumbsUp" ? "👍" : "👎"}`,
  },
  "chat:posthog.distinctId": {
    colors: { circle: "fill-[#f0b429]" },
    label: "Posthog Session",
    href: (value) => constructPosthogURL(value),
  },
  "chat:datadog.sessionId": {
    colors: { circle: "fill-[#774aa4]" },
    label: "Datadog Session",
    href: (value) => constructDatadogURL(value),
  },
  "nlp:assistant.completeness": {
    colors: {
      Incomplete: {
        circle: "fill-red-500",
        text: "text-red-700",
        background: "bg-red-50",
      },
      Partial: {
        circle: "fill-yellow-500",
        text: "text-yellow-700",
        background: "bg-yellow-50",
      },
      Complete: {
        circle: "fill-green-500",
        text: "text-green-700",
        background: "bg-green-50",
      },
    },
    label: (value: string) => `Response ${value}`,
  },
  "nlp:user.sentiment": {
    colors: {
      Negative: {
        circle: "fill-red-500",
        text: "text-red-700",
        background: "bg-red-50",
      },
      Neutral: {
        circle: "fill-gray-500",
        text: "text-gray-700",
        background: "bg-gray-50",
      },
      Positive: {
        circle: "fill-green-500",
        text: "text-green-700",
        background: "bg-green-50",
      },
    },
    label: (value: string) => `Sentiment: ${value}`,
  },
  "nlp:user.language": {
    colors: {
      circle: "fill-indigo-500",
      text: "text-indigo-700",
      background: "bg-indigo-50",
      border: "ring-indigo-200",
    },
    label: (value: string) => {
      const langInfo = findClosestLanguageMatch(value);
      return `${langInfo?.emoji} ${langInfo?.shortLocalizedName}`;
    },
  },
  "nlp:assistant.language": {
    colors: {
      circle: "fill-indigo-500",
      text: "text-indigo-700",
      background: "bg-indigo-50",
      border: "ring-indigo-200",
    },
    label: (value: string) => {
      const langInfo = findClosestLanguageMatch(value);
      return `${langInfo?.emoji} ${langInfo?.shortLocalizedName}`;
    },
  },
};

const publicTags = [
  "nlp:user.language",
  "nlp:assistant.language",
  "chat:rating",
  "nlp:assistant.completeness",
  "nlp:user.sentiment",
];

function constructPosthogURL(sessionId: string, projectId = 13405): string {
  const baseUrl = `https://eu.posthog.com/project/${projectId}/events`;
  const queryObject = {
    kind: "DataTableNode",
    full: true,
    source: {
      kind: "EventsQuery",
      select: [
        "*",
        "event",
        "person",
        "coalesce(properties.$current_url, properties.$screen_name) -- Url / Screen",
        "properties.$lib",
        "timestamp",
      ],
      orderBy: ["timestamp DESC"],
      after: "-24h",
      properties: [
        {
          key: "$session_id",
          value: [sessionId],
          operator: "exact",
          type: "event",
        },
      ],
    },
    propertiesViaUrl: true,
    showSavedQueries: true,
    showPersistentColumnConfigurator: true,
  };

  return `${baseUrl}#q=${encodeURIComponent(JSON.stringify(queryObject))}`;
}

function constructDatadogURL(
  sessionId: string,
  applicationId = "2075098b-155f-4050-b4bb-1bbd7b4af755"
): string {
  const baseUrl = "https://app.datadoghq.eu/rum/sessions";
  const queryParams = new URLSearchParams({
    query: `@type:session @application.id:${applicationId} @session.id:${sessionId}`,
  });
  return `${baseUrl}?${queryParams.toString()}`;
}

function TagComponent({ tag }: { tag: Tag }) {
  const config = TAG_CONFIG[tag.name];
  if (!config) {
    return (
      <span className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
        <svg className="h-1.5 w-1.5 fill-gray-500" viewBox="0 0 6 6">
          <circle cx={3} cy={3} r={3} />
        </svg>
        {tag.name} = {tag.value}
      </span>
    );
  }

  const colors =
    typeof config.colors === "function"
      ? config.colors(tag.value)
      : config.colors;
  const label =
    typeof config.label === "function" ? config.label(tag.value) : config.label;
  const href = config.href?.(tag.value);

  const TagWrapper = href ? "a" : "span";
  const wrapperProps = href ? { href, target: "_blank" } : {};

  const colorStyles =
    typeof colors === "object" && "circle" in colors
      ? colors
      : colors?.[tag.value as keyof typeof colors] || {
          circle: "fill-gray-500",
        };

  return (
    <TagWrapper
      {...wrapperProps}
      className={`inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset 
        ${colorStyles.background || "bg-white"} 
        ${colorStyles.text || "text-gray-900"} 
        ${colorStyles.border || "ring-gray-200"}`}
    >
      <svg className={`h-1.5 w-1.5 ${colorStyles.circle}`} viewBox="0 0 6 6">
        <circle cx={3} cy={3} r={3} />
      </svg>
      {label || `${tag.name} = ${tag.value}`}
    </TagWrapper>
  );
}

export function Tags({ tags }: { tags: Tag[] }) {
  const { user } = useUser();
  const isAdmin = user?.publicMetadata?.sys_admin;
  const viewableTags = tags
    .filter((tag) => publicTags.includes(tag.name) || isAdmin)
    .sort((a, b) => {
      const aIndex = publicTags.indexOf(a.name);
      const bIndex = publicTags.indexOf(b.name);
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });

  return (
    <div className="flex flex-wrap gap-2 text-slate-500">
      {viewableTags.map((tag, index) => (
        <TagComponent key={index} tag={tag} />
      ))}
    </div>
  );
}
