import { useEffect, useState } from "react";
import Spinner from "./Loading";
import { useUser } from "@clerk/clerk-react";

function IFrameWrapper({ src }: { src: string }) {
  return (
    <div style={{ height: "calc(100dvh - 4rem)" }} className="flex flex-col">
      <div className="flex-grow">
        <iframe
          className="w-full h-full"
          id="iframe"
          src={src}
          scrolling="no"
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  );
}

export default function AdminDashboard() {
  const [url, setUrl] = useState<string>("");
  const { user } = useUser();
  const isAdmin = user?.publicMetadata?.sys_admin;

  useEffect(() => {
    const getCredentials = async () => {
      const platformToken = await (window as any).Clerk.session.getToken({
        template: "api_botbrains_io",
      });
      setUrl(
        `${
          import.meta.env.VITE_DASHBOARD_URL
        }?embed=true&token=${platformToken}`
      );
    };
    getCredentials();
  });
  if (!isAdmin) return <></>;
  if (url === "") return <Spinner />;
  return <IFrameWrapper src={url} />;
}
