import { useUser } from "@clerk/clerk-react";
import CenteredLayout from "./CenteredLayout";
import { Label } from "./ui/label";
import { Switch } from "./ui/switch";
import { useParams } from "react-router-dom";

export default function ProjectSettingsPage() {
  const { user } = useUser();
  const { projectId } = useParams();

  if (projectId === undefined) {
    throw new Error("project_id is undefined");
  }

  return (
    <CenteredLayout>
      <div>
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
          Settings
        </h1>
        <h2 className="mt-8 text-xl font-bold leading-tight tracking-tight text-gray-900">
          Notifications
        </h2>
        <div>
          <div className="mt-8 flex items-center space-x-2">
            <Switch
              checked={
                (user?.unsafeMetadata?.[projectId] as any)?.notifications
                  ?.weekly_digest_email ?? true
              }
              onCheckedChange={(checked) => {
                user?.update({
                  unsafeMetadata: {
                    ...user.unsafeMetadata,
                    [projectId]: {
                      ...(user.unsafeMetadata[projectId] ?? {}),
                      notifications: {
                        ...((user.unsafeMetadata[projectId] as any)
                          ?.notifications ?? {}),
                        weekly_digest_email: checked,
                      },
                    },
                  },
                });
              }}
            />
            <Label>Receive Weekly Digest</Label>
          </div>
          <p className="mt-2 text-sm text-gray-500">
            Receive a weekly digest of your project's activity and insights.
          </p>
        </div>
      </div>
    </CenteredLayout>
  );
}
