import { ComparatorToName, Testcase } from "@/structs";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAppDispatch,
  usePollPendingTestSuiteRuns,
  useTestCasesByTestSuite,
  useTestSuiteRunsByTestSuite,
  useTestSuites,
} from "../../hooks";
import Spinner from "../Loading";
import TestSuiteRunTable from "./TestSuiteRunTable";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { deleteTestSuite } from "@/slices/evaluations";
import { unwrapResult } from "@reduxjs/toolkit";
import { useState } from "react";
import { EvaluationBreadcrumbsNavbar } from "../BreadcrumbNav";
import WithTestSuiteSidebar from "./WithTestSuiteSidebar";
import { Alert, AlertDescription } from "@/components/ui/alert";
import {
  MoreVertical,
  Plus,
  Beaker,
  Edit2,
  Trash2,
  AlertTriangle,
} from "lucide-react";

function TestCaseCard({ testCase }: { testCase: Testcase }) {
  const navigate = useNavigate();
  const latestMessage =
    testCase.input.messages[testCase.input.messages.length - 1]?.content;

  return (
    <Card
      className="h-full transition-all hover:border-blue-200 hover:shadow-md cursor-pointer"
      onClick={() =>
        navigate(
          `/${testCase.project_id}/testsuites/${testCase.testsuite_id}/testcases/${testCase.id}`
        )
      }
    >
      <CardHeader className="space-y-1 p-4">
        <div className="flex items-center gap-2">
          <Beaker className="h-4 w-4 text-blue-500" />
          <CardTitle className="text-lg">{testCase.name}</CardTitle>
        </div>
        <CardDescription>
          {ComparatorToName[testCase.check.comparator]} Check
        </CardDescription>
      </CardHeader>
      <CardContent>
        <p className="text-sm text-gray-600 line-clamp-3">{latestMessage}</p>
      </CardContent>
    </Card>
  );
}

function TestCaseList({ testCases }: { testCases: Testcase[] }) {
  if (testCases.length === 0) {
    return null;
  }

  return (
    <div className="mt-6 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
      {testCases.map((testCase) => (
        <TestCaseCard key={testCase.id} testCase={testCase} />
      ))}
    </div>
  );
}

function TestSuiteDropdownMenu({
  projectId,
  testSuiteId,
  testSuiteName,
}: {
  projectId: number;
  testSuiteId: string;
  testSuiteName: string;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  async function handleDelete() {
    setIsDeleting(true);
    try {
      const actionResult = await dispatch(
        deleteTestSuite({ project_id: projectId, testsuite_id: testSuiteId })
      );
      unwrapResult(actionResult);
      navigate(`/${projectId}/testsuites`);
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setIsDeleting(false);
    }
  }

  if (isDeleting) return <Spinner />;
  if (errorMessage) {
    return (
      <Alert variant="destructive">
        <AlertTriangle className="h-4 w-4" />
        <AlertDescription>{errorMessage}</AlertDescription>
      </Alert>
    );
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
          <MoreVertical className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={() =>
            navigate(`/${projectId}/testsuites/${testSuiteId}/edit`)
          }
        >
          <Edit2 className="mr-2 h-4 w-4" />
          Edit Test Suite
        </DropdownMenuItem>
        <DropdownMenuItem
          className="text-red-600 focus:text-red-600"
          onClick={() => {
            if (
              window.confirm(
                `Are you sure you want to delete "${testSuiteName}"?`
              )
            ) {
              handleDelete();
            }
          }}
        >
          <Trash2 className="mr-2 h-4 w-4" />
          Delete Test Suite
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function TestSuitePage() {
  const { projectId, testSuiteId } = useParams();
  const navigate = useNavigate();

  const {
    testSuites,
    status: testSuitesStatus,
    errorMessage: testSuitesErrorMessage,
  } = useTestSuites({
    project_id: Number(projectId),
  });

  const {
    testSuiteRuns,
    status: testSuiteRunsStatus,
    errorMessage: testSuiteRunErrorMessage,
  } = useTestSuiteRunsByTestSuite({
    project_id: Number(projectId),
    testSuiteId: testSuiteId!,
  });

  const {
    testCases,
    status: testCasesStatus,
    errorMessage: testCasesErrorMessage,
  } = useTestCasesByTestSuite({
    project_id: Number(projectId),
    testSuiteId: testSuiteId!,
  });

  usePollPendingTestSuiteRuns({ project_id: Number(projectId) });

  const testSuite = testSuites.find((ts) => ts.id === testSuiteId);

  if (!projectId || !testSuiteId) {
    throw new Error("Missing required parameters");
  }

  if (testSuitesStatus === "loading" || testSuiteRunsStatus === "loading") {
    return <Spinner />;
  }

  if (testSuitesStatus === "failed") {
    return (
      <Alert variant="destructive">
        <AlertTriangle className="h-4 w-4" />
        <AlertDescription>{testSuitesErrorMessage}</AlertDescription>
      </Alert>
    );
  }

  if (testSuiteRunsStatus === "failed") {
    return (
      <Alert variant="destructive">
        <AlertTriangle className="h-4 w-4" />
        <AlertDescription>{testSuiteRunErrorMessage}</AlertDescription>
      </Alert>
    );
  }

  if (testCasesStatus === "failed") {
    return (
      <Alert variant="destructive">
        <AlertTriangle className="h-4 w-4" />
        <AlertDescription>{testCasesErrorMessage}</AlertDescription>
      </Alert>
    );
  }

  if (!testSuite) {
    return (
      <Alert>
        <AlertTriangle className="h-4 w-4" />
        <AlertDescription>Test Suite not found</AlertDescription>
      </Alert>
    );
  }

  return (
    <WithTestSuiteSidebar
      projectId={Number(projectId)}
      selectedTestSuiteId={testSuiteId}
    >
      <div className="space-y-8">
        <div>
          <EvaluationBreadcrumbsNavbar
            items={[
              { href: "./..", label: "Test Suites" },
              { label: testSuite.name },
            ]}
          />
          <div className="mt-4 flex items-center justify-between">
            <div>
              <h1 className="text-3xl font-bold tracking-tight">
                {testSuite.name}
              </h1>
              {testSuite.description && (
                <p className="mt-2 text-gray-500">{testSuite.description}</p>
              )}
            </div>
            <TestSuiteDropdownMenu
              projectId={Number(projectId)}
              testSuiteId={testSuiteId}
              testSuiteName={testSuite.name}
            />
          </div>
        </div>

        <TestSuiteRunTable
          testSuiteRuns={testSuiteRuns}
          createButtonEnabled={testCases.length > 0}
        />

        <div>
          <div className="mb-6 flex items-center justify-between">
            <h2 className="text-xl font-semibold">Test Cases</h2>
            <Button
              className="bg-blue-500 hover:bg-blue-600"
              onClick={() =>
                navigate(
                  `/${projectId}/testsuites/${testSuiteId}/testcases/create`
                )
              }
              size="sm"
            >
              <Plus className="mr-2 h-4 w-4" />
              Add Test Case
            </Button>
          </div>

          {testCasesStatus === "loading" ? (
            <Spinner />
          ) : testCases.length > 0 ? (
            <TestCaseList testCases={testCases} />
          ) : (
            <Card className="flex flex-col items-center justify-center py-12">
              <CardContent className="text-center">
                <Beaker className="mx-auto mb-4 h-12 w-12 text-gray-400" />
                <h3 className="mb-2 text-lg font-medium">No Test Cases Yet</h3>
                <p className="mb-6 text-sm text-gray-500">
                  Get started by creating your first test case
                </p>
                <Button
                  onClick={() =>
                    navigate(
                      `/${projectId}/testsuites/${testSuiteId}/testcases/create`
                    )
                  }
                >
                  <Plus className="mr-2 h-4 w-4" />
                  Add Your First Test Case
                </Button>
              </CardContent>
            </Card>
          )}
        </div>
      </div>
    </WithTestSuiteSidebar>
  );
}

export default TestSuitePage;
