import { useTestSuites } from "@/hooks";
import { useNavigate } from "react-router-dom";
import LinkWithQuery from "../LinkWithQuery";
import { TestTubes, Plus, AlertTriangle, ChevronRight } from "lucide-react";
import { Alert, AlertDescription } from "../ui/alert";
import { ScrollArea } from "../ui/scroll-area";
import { cn } from "@/lib/utils";
import { Button } from "../ui/button";
import { Skeleton } from "../ui/skeleton";
import { useIsMobile } from "@/hooks/use-mobile";

interface TestSuiteSidebarProps {
  projectId: number;
  selectedTestSuiteId: string | undefined;
}

function TestSuiteSkeleton() {
  return (
    <div className="space-y-2 px-2 py-2">
      {Array.from({ length: 5 }).map((_, i) => (
        <div
          key={i}
          className="flex items-center justify-between rounded-md px-3 py-2"
        >
          <Skeleton className="h-5 w-32" />
          <Skeleton className="h-4 w-4" />
        </div>
      ))}
    </div>
  );
}

function TestSuiteSidebar({
  projectId,
  selectedTestSuiteId,
}: TestSuiteSidebarProps) {
  const navigate = useNavigate();
  const { testSuites, status, errorMessage } = useTestSuites({
    project_id: Number(projectId),
  });
  const isMobile = useIsMobile();

  if (status === "failed") {
    return (
      <Alert variant="destructive" className="mx-4 my-2">
        <AlertTriangle className="h-4 w-4" />
        <AlertDescription>{errorMessage}</AlertDescription>
      </Alert>
    );
  }

  return (
    <aside
      className={`flex h-full ${isMobile ? "w-full" : "w-64"} flex-col border-r border-gray-200`}
    >
      <div className="flex items-center justify-between border-b border-gray-200 bg-gray-50/50 p-4">
        <div className="flex items-center gap-2">
          <TestTubes className="h-5 w-5 text-blue-500" />
          <h2 className="font-semibold text-gray-900">Test Suites</h2>
        </div>
        <Button
          size="sm"
          variant="outline"
          onClick={() => navigate(`/${projectId}/testsuites/create`)}
          className="h-8 w-8 p-0"
        >
          <Plus className="h-4 w-4" />
        </Button>
      </div>

      {status === "loading" ? (
        <div className="flex-1">
          <TestSuiteSkeleton />
        </div>
      ) : (
        <ScrollArea className="flex-1 w-full">
          <nav className="space-y-1 p-2">
            {testSuites.map((testSuite) => (
              <LinkWithQuery
                key={testSuite.id}
                to={`/${projectId}/testsuites/${testSuite.id}`}
                className={cn(
                  "group flex items-center justify-between rounded-md px-3 py-2 text-sm font-medium transition-colors",
                  testSuite.id === selectedTestSuiteId
                    ? "bg-blue-50 text-blue-600"
                    : "text-gray-700 hover:bg-gray-100/80"
                )}
              >
                <span className="truncate">{testSuite.name}</span>
                <ChevronRight
                  className={cn(
                    "h-4 w-4 transition-opacity",
                    testSuite.id === selectedTestSuiteId
                      ? "text-blue-600 opacity-100"
                      : "text-gray-400 opacity-0 group-hover:opacity-100"
                  )}
                />
              </LinkWithQuery>
            ))}
          </nav>
        </ScrollArea>
      )}
    </aside>
  );
}

export default TestSuiteSidebar;
