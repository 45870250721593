import { useParams, useSearchParams } from "react-router-dom";
import TestSuiteForm, { TestSuiteSubmit } from "./TestSuiteForm";
import { createTestSuite } from "@/slices/evaluations";
import { useAppDispatch } from "@/hooks";
import CenteredLayout from "../CenteredLayout";
import { EvaluationBreadcrumbsNavbar } from "../BreadcrumbNav";

function TestSuiteCreatePage() {
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const defaultValues = {
    project_id: Number(projectId),
    name: searchParams.get("name") ?? "",
    description: searchParams.get("description") ?? "",
  };

  const handleCreateTestSuite = async (values: TestSuiteSubmit) =>
    await dispatch(createTestSuite(values));
  return (
    <CenteredLayout>
      <EvaluationBreadcrumbsNavbar
        items={[{ href: "./..", label: "Test Suites" }, { label: "Create " }]}
      />
      <TestSuiteForm
        initialValues={defaultValues}
        handleSubmitAsync={handleCreateTestSuite}
      />
    </CenteredLayout>
  );
}

export default TestSuiteCreatePage;
