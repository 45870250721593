import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface MetricQuery {
  metricName: string;
  since: string;
  until: string;
  interval: string;
  account?: number[];
  project?: number[];
  alias?: string[];
  deployment?: number[];
}

interface MetricsState {
  data: { [key: string]: any };
  status: { [key: string]: "idle" | "loading" | "succeeded" | "failed" };
  errorMessage: { [key: string]: string | null };
}

const initialState: MetricsState = {
  data: {},
  status: {},
  errorMessage: {},
};

export function queryToString(query: MetricQuery): string {
  const filters: any = { ...query };
  filters.metric_name = filters.metricName;
  delete filters.metricName;
  return filtersToString(filters);
}

function filtersToString(filters: {
  [key: string]: (string | number | boolean)[] | string | number | boolean;
}): string {
  const keyValuePairs = Object.entries(filters)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((val) => {
          return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
        });
      } else {
        // Encode non-array values
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
    })
    .flat();
  return keyValuePairs.sort().join("&");
}

export const fetchMetricsData = createAsyncThunk(
  "metrics/fetchMetricsData",
  async ({ query }: { query: string }, { rejectWithValue }) => {
    const apiToken = await (window as any).Clerk.session.getToken({ template: "api_botbrains_io" });
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/v1/metrics?${query}`,
        {
          headers: {
            authorization: `Bearer ${apiToken}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return await response.json();
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
  {
    condition: (props, { getState }) => {
      const {query} = props;
      const { metrics } = getState() as RootState;
      if (metrics.status[query] === "loading" || metrics.status[query] === "succeeded") {
        return false;
      }
    },
  }
);

export const metricsSlice = createSlice({
  name: "metrics",
  initialState,
  reducers: {
    // synchronous reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMetricsData.pending, (state, action) => {
        state.status[action.meta.arg.query] = "loading";
      })
      .addCase(fetchMetricsData.fulfilled, (state, action) => {
        state.status[action.meta.arg.query] = "succeeded";
        state.data[action.meta.arg.query] = action.payload;
      })
      .addCase(fetchMetricsData.rejected, (state, action) => {
        state.status[action.meta.arg.query] = "failed";
        state.errorMessage[action.meta.arg.query] = action.payload as string;
      });
  },
});

// Selectors
export const selectMetricDataById = (query: string) => (state: RootState) =>
  state.metrics.data[query];
export const selectMetricsStatus = (query: string) => (state: RootState) =>
  state.metrics.status[query] || "idle";
export const selectMetricsError = (query: string) => (state: RootState) =>
  state.metrics.errorMessage[query] || null;

export default metricsSlice.reducer;
