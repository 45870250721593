import TestCaseForm, {
  TestCaseSubmit,
} from "@/components/evaluation/TestCaseForm";
import { useAppDispatch, useTestSuites } from "@/hooks";
import { CheckSchema, ConversationSchema } from "@/lib/schemas";
import { createTestCase } from "@/slices/evaluations";
import { useParams, useSearchParams } from "react-router-dom";
import { z } from "zod";
import CenteredLayout from "../CenteredLayout";
import { EvaluationBreadcrumbsNavbar } from "../BreadcrumbNav";
import { decodeParams } from "@/lib/utils";

const TestParamsSchema = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
  input: ConversationSchema.optional(),
  check: CheckSchema.optional(),
});

function TestCaseCreatePage() {
  const { projectId, testSuiteId } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  if (!testSuiteId) {
    throw new Error("Logical Error: testsuite_id is required");
  }

  const { testSuites } = useTestSuites({ project_id: Number(projectId) });
  const testSuite = testSuites.find((t) => t.id === testSuiteId);
  console.log("Test Suite:", testSuite);
  // @ts-expect-error TODO(memben): fix this
  const params = decodeParams(searchParams, TestParamsSchema);

  const initialValues = {
    project_id: Number(projectId),
    testsuite_id: testSuiteId,
    name: params?.name ?? "",
    description: params?.description ?? "",
    input: params?.input ?? { messages: [{ role: "user", content: "" }] },
    check: params?.check ?? {
      comparator: "SEMANTICALLY_EQUIVALENT",
      expected: "",
      threshold: 0.8,
    },
  };

  const handleCreateTestCase = async (values: TestCaseSubmit) =>
    await dispatch(createTestCase(values));
  return (
    <CenteredLayout>
      <EvaluationBreadcrumbsNavbar
        items={[
          { href: "./../../..", label: "Test Suites" },
          {
            href: "./../..",
            label: testSuite?.name ?? "Test Suite",
          },
          {
            label: "Test Cases",
          },
          {
            href: ".",
            label: "Create",
          },
        ]}
      />
      <TestCaseForm
        initialValues={initialValues}
        handleSubmitAsync={handleCreateTestCase}
      />
    </CenteredLayout>
  );
}

export default TestCaseCreatePage;
