import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { addDays, subDays } from "date-fns";
import {
  useMessageCountByDay,
  useMessageCountByHour,
} from "@/components/charts/hooks";
import { useNavigate } from "react-router-dom";
import { UTCDateMini } from "@date-fns/utc";

import { useCurrentProject } from "@/hooks";
import { Fragment } from "react";

const DAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const HOURS = Array.from({ length: 24 }, (_, i) => `${i}h`);
const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

type ThreeLetterMonth =
  | "Jan"
  | "Feb"
  | "Mar"
  | "Apr"
  | "May"
  | "Jun"
  | "Jul"
  | "Aug"
  | "Sep"
  | "Oct"
  | "Nov"
  | "Dec";

const getDaysInMonth = (month: ThreeLetterMonth) => {
  const daysInMonth = {
    Jan: 31,
    Feb: 29,
    Mar: 31,
    Apr: 30,
    May: 31,
    Jun: 30,
    Jul: 31,
    Aug: 31,
    Sep: 30,
    Oct: 31,
    Nov: 30,
    Dec: 31,
  };
  return daysInMonth[month];
};

interface IntensityColors {
  [key: number]: string;
}

const getIntensityColor = (value: number): string => {
  const intensities: IntensityColors = {
    0: "bg-blue-50 hover:bg-blue-100",
    1: "bg-blue-100 hover:bg-blue-200",
    2: "bg-blue-200 hover:bg-blue-300",
    3: "bg-blue-300 hover:bg-blue-400",
    4: "bg-blue-400 hover:bg-blue-500",
  };
  return intensities[value] || intensities[0];
};
// Map counts to intensity levels 0-4
const getIntensityLevel = (value: number, max: number) => {
  if (value === 0) return 0;
  const step = max / 4;
  return Math.min(4, Math.ceil(value / step));
};

export type OnHourCellClick = (
  day: string,
  hour: string,
  value: number
) => void;
export type OnDayCellClick = (
  month: string,
  day: number,
  value: number
) => void;

const HourlyHeatmap = ({ onCellClick }: { onCellClick?: OnHourCellClick }) => {
  const since = subDays(new Date(), 7);
  const until = new Date();
  const { data, status } = useMessageCountByHour(since, until);

  if (status !== "succeeded") {
    return <div className="h-64 w-full animate-pulse rounded bg-gray-200" />;
  }

  // Find max value for scaling
  const maxValue = Math.max(...data.map((d) => d.value));

  // Create a lookup map for easy data access
  const activityMap = data.reduce((acc: { [key: string]: number }, item) => {
    const key = `${item.weekday}-${item.day}`;
    acc[key] = item.value;
    return acc;
  }, {});

  return (
    <div className="w-full overflow-x-auto">
      {/* <div className="mb-2 text-sm text-gray-600">
        Total messages: {total} | Average per hour: {average.toFixed(1)}
      </div> */}
      <div
        className="grid gap-1 min-w-[600px] mt-1"
        style={{
          gridTemplateRows: `repeat(7, 35px) 35px`,
          gridTemplateColumns: `50px repeat(24, minmax(35px, 1rem))`,
        }}
      >
        {DAYS.map((day) => (
          <>
            <div
              key={`${day}-label`}
              className="col-span-1 text-sm font-medium text-gray-600 pr-4 flex items-center justify-end"
            >
              {day}
            </div>

            {HOURS.map((hour) => {
              const value = activityMap[`${day}-${hour.replace("h", "")}`] || 0;
              const intensity = getIntensityLevel(value, maxValue);

              return (
                <TooltipProvider key={`${day}-${hour}`}>
                  <Tooltip delayDuration={20}>
                    <TooltipTrigger asChild>
                      <div
                        className={`${getIntensityColor(intensity)} 
                          rounded transition-all duration-200 cursor-pointer
                          hover:scale-105 hover:ring-2 hover:ring-blue-500/50
                          active:scale-95`}
                        onClick={() => onCellClick?.(day, hour, value)}
                      />
                    </TooltipTrigger>
                    <TooltipContent>
                      {`${value} message${value !== 1 ? "s" : ""} on ${day} at ${hour}`}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              );
            })}
          </>
        ))}

        <div className="col-span-1" />
        {HOURS.map((hour) => (
          <div
            key={hour}
            className="text-xs font-medium text-gray-600 flex items-center justify-center"
          >
            {hour}
          </div>
        ))}
      </div>
    </div>
  );
};

const DailyHeatmap = ({ onCellClick }: { onCellClick?: OnDayCellClick }) => {
  const currentYear = new Date().getFullYear();
  const { data, status } = useMessageCountByDay(currentYear);

  if (status !== "succeeded") {
    return <div className="h-64 w-full animate-pulse rounded bg-gray-200" />;
  }

  // Find max value for scaling
  const maxValue = Math.max(...data.map((d) => d.value));

  // Create a lookup map for easy data access
  const activityMap = data.reduce((acc: { [key: string]: number }, item) => {
    const key = `${item.month}-${item.day}`;
    acc[key] = item.value;
    return acc;
  }, {});

  return (
    <div className="w-full overflow-x-auto">
      {/* <div className="mb-2 text-sm text-gray-600">
        Total messages: {total} | Average per day: {average.toFixed(1)}
      </div> */}
      <div
        className="grid gap-1 min-w-[800px] mt-1"
        style={{
          gridTemplateRows: `repeat(12, 30px) repeat(1, 30px)`,
          gridTemplateColumns: `50px repeat(31, minmax(30px, 1rem))`,
        }}
      >
        {MONTHS.map((month) => (
          <Fragment key={`${month}-label`}>
            <div className="col-span-1 text-sm font-medium text-gray-600 pr-4 flex items-center justify-end">
              {month}
            </div>

            {Array.from({ length: 31 }, (_, dayIndex) => {
              const isValidDay =
                dayIndex < getDaysInMonth(month as ThreeLetterMonth);
              const value = isValidDay
                ? activityMap[`${month}-${dayIndex + 1}`] || 0
                : -1;
              const intensity = isValidDay
                ? getIntensityLevel(value, maxValue)
                : -1;

              return (
                <TooltipProvider key={`${month}-${dayIndex + 1}`}>
                  <Tooltip delayDuration={20}>
                    <TooltipTrigger asChild>
                      <div
                        className={`${
                          value >= 0
                            ? getIntensityColor(intensity)
                            : "bg-gray-50"
                        } rounded transition-all duration-200 ${
                          isValidDay
                            ? "cursor-pointer hover:scale-105 hover:ring-2 hover:ring-blue-500/50 active:scale-95"
                            : ""
                        }`}
                        onClick={() =>
                          isValidDay &&
                          onCellClick?.(month, dayIndex + 1, value)
                        }
                      />
                    </TooltipTrigger>
                    <TooltipContent>
                      {isValidDay
                        ? `${value} message${value !== 1 ? "s" : ""} on ${month} ${dayIndex + 1}`
                        : `Invalid date: ${month} ${dayIndex + 1}`}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              );
            })}
          </Fragment>
        ))}

        <div className="col-span-1" />
        {Array.from({ length: 31 }, (_, i) => (
          <div
            key={i}
            className="text-xs font-medium text-gray-600 flex items-center justify-center"
          >
            {i + 1}
          </div>
        ))}
        <div className="col-span-full" />
      </div>
    </div>
  );
};

const HeatmapCard = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <Card className="w-full max-w-7xl">
      <CardHeader>
        <CardTitle className="text-lg font-semibold">{title}</CardTitle>
      </CardHeader>
      <CardContent>
        {children}

        <div className="mt-8 flex items-center justify-end gap-2 text-sm text-gray-600">
          <span>Less</span>
          {[0, 1, 2, 3, 4].map((value) => (
            <div
              key={value}
              className={`w-6 h-6 rounded ${getIntensityColor(value)}`}
            />
          ))}
          <span>More</span>
        </div>
      </CardContent>
    </Card>
  );
};

export const WeeklyMessagesHeatmap = () => {
  return (
    <HeatmapCard title="Messages Heatmap">
      <HourlyHeatmap onCellClick={console.log} />
    </HeatmapCard>
  );
};

export const YearlyMessagesHeatmap = () => {
  const { project } = useCurrentProject();
  const navgiate = useNavigate();
  const goToMessages = (month: string, day: number) => {
    const since = new UTCDateMini(
      new Date().getFullYear(),
      MONTHS.indexOf(month),
      day
    );
    const until = addDays(since, 1);
    navgiate(
      `/${project?.id}/messages/search?since=${since.toISOString()}&until=${until.toISOString()}`
    );
  };
  return (
    <HeatmapCard title="Messages Heatmap">
      <DailyHeatmap onCellClick={goToMessages} />
    </HeatmapCard>
  );
};
