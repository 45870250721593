import { useParams } from "react-router-dom";
import { useConversation } from "../../hooks";
import Spinner from "../Loading";
import { AxiosErrorBox } from "../Error";
import FrameSessionCard from "./FrameSessionCard";
import { BreadcrumbNavigation } from "../BreadcrumbNav";
import { ArrowUpRight, MessageCircle } from "lucide-react";
import Conversation from "./Conversation";

const formatConversationDate = (date: Date) => {
  return new Intl.DateTimeFormat("default", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }).format(new Date(date));
};

export default function ConversationPage() {
  const { projectId, conversationId } = useParams();
  const {
    data: conversation,
    status,
    error,
  } = useConversation({
    projectId: Number(projectId),
    conversationId: conversationId as string,
  });

  if (status === "pending") return <Spinner />;
  if (status === "error") return <AxiosErrorBox error={error} />;

  return (
    <div className="min-h-screen bg-gray-50/50 pb-24 pt-8">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <BreadcrumbNavigation
          items={[
            { href: `./..`, label: "Conversations" },
            { label: "Conversation" },
          ]}
        />
        <header className="mb-8">
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
            <div>
              <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
                Conversation History
              </h1>
              <span className="mt-2 text-sm text-gray-600">
                {conversation.messages.length} messages exchanged, started at{" "}
                {formatConversationDate(conversation.created_at)}
              </span>
            </div>
            {conversation.frame_session && (
              <div className="flex flex-wrap gap-4">
                <button
                  className="inline-flex items-center gap-2 rounded-lg border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  onClick={() => window.$crisp.push(["do", "chat:open"])}
                >
                  Give Feedback
                  <MessageCircle className="h-4 w-4" />
                </button>
                <button
                  className="inline-flex items-center gap-2 rounded-lg bg-primary px-4 py-2 text-sm font-medium text-white hover:bg-primary/90"
                  onClick={() =>
                    window.open(
                      `${import.meta.env.VITE_CHAT_URL}/iframe/editor?frameId=${conversation.frame_session!.frame_id}`,
                      "_blank"
                    )
                  }
                >
                  Chat Now
                  <ArrowUpRight className="h-4 w-4" />
                </button>
              </div>
            )}
          </div>
        </header>

        <div className="w-full">
          <div className="flex flex-col lg:flex-row gap-6">
            <main className="flex-grow">
              <Conversation conversation={conversation} />
            </main>
            {conversation.frame_session && (
              <div className="w-full lg:w-80 flex-shrink-0">
                <div className="lg:sticky lg:top-6">
                  <FrameSessionCard frameSession={conversation.frame_session} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
