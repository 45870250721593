import { FormDescription, FormLabel } from "./ui/form";
import { LucideIcon } from "lucide-react";

export const SettingsField = ({
  icon: Icon,
  label,
  description,
  children,
}: {
  icon: LucideIcon;
  label: string;
  description: string;
  children: React.ReactNode;
}) => (
  <div className="space-y-2">
    <div className="flex items-center gap-2">
      <Icon className="h-4 w-4 text-blue-500" />
      <FormLabel className="text-sm font-medium text-gray-700">
        {label}
      </FormLabel>
    </div>
    <FormDescription className="text-sm text-gray-500">
      {description}
    </FormDescription>
    {children}
  </div>
);
