import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface Project {
  id: number;
  name: string;
  description: string;
  account_id: number;
  created_at: string;
  updated_at: string;
  website_url: string | null;
  preview_url: string | null;
}

interface ProjectsState {
  projects: Project[];
  status: "idle" | "loading" | "succeeded" | "failed";
  errorMessage: string | null;
}

const initialState: ProjectsState = {
  projects: [],
  status: "idle",
  errorMessage: null,
};

// Async thunk for fetching projects
// NOTE(liamvdv): this must only be called after Clerk has loaded. E. g. in SignedIn component.
export const fetchProjects = createAsyncThunk(
  "projects/fetchProjects",
  async (_, { rejectWithValue }) => {
    // Proper way: commented out, but hooks are not allowed here, thus global object.
    // const { getToken } = useAuth();
    const apiToken = await (window as any).Clerk.session.getToken({
      template: "api_botbrains_io",
    });
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/v1/projects`,
        {
          headers: {
            authorization: `Bearer ${apiToken}`,
          },
        }
      );
      if (!response.ok) {
        // TODO(liamvdv): response.json().detail
        throw new Error("Network response was not ok");
      }
      return await response.json();
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
  // https://redux-toolkit.js.org/api/createAsyncThunk#canceling-before-execution
  {
    condition: (_, { getState }) => {
      const { projects } = getState() as RootState;
      if (projects.status !== "idle") {
        // will ignore failed
        return false;
      }
    },
  }
);

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    // synchronous reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { projects } = action.payload; // API response is { projects: [] }
        state.projects = projects;
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.status = "failed";
        state.errorMessage = action.payload as string;
      });
  },
});

// Selectors
export const selectProjects = (state: RootState) => state.projects.projects;
export const selectProjectsStatus = (state: RootState) => state.projects.status;
export const selectProjectsError = (state: RootState) =>
  state.projects.errorMessage;

export default projectsSlice.reducer;
