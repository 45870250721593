import { useCurrentProject } from "../hooks";
import { Link, useSearchParams } from "react-router-dom";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { ArrowUpRight } from "lucide-react";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { UTCDate } from "@date-fns/utc";
import { ConversationsMetadataListV2 } from "./conversations/ConversationsMetadataPage";
import { TripletMetricsCard } from "./charts/MetricCards";

export default function ProjectDashboard() {
  const { project } = useCurrentProject();
  const [searchParams] = useSearchParams();

  if (!project) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="h-8 w-8 animate-spin rounded-full border-4 border-primary border-t-transparent" />
      </div>
    );
  }

  const since = startOfDay(subDays(new Date(), 7)).toISOString();
  const until = endOfDay(new UTCDate()).toISOString();

  return (
    <div className="min-h-screen bg-gray-50/50">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        {/* Header Section */}
        <div className="mb-8 space-y-4">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-4xl font-bold text-gray-900">
                {project.name}
              </h1>
              <p className="mt-2 text-lg text-gray-600">
                {project.description}
              </p>
            </div>
            <button
              className="inline-flex items-center gap-2 rounded-lg bg-primary px-4 py-2 text-sm font-medium text-white hover:bg-primary/90"
              // @ts-ignore
              onClick={() => window.$crisp.push(["do", "chat:open"])}
            >
              Give Feedback
              <ArrowUpRight className="h-4 w-4" />
            </button>
          </div>
        </div>

        {/* Metrics Section */}
        <TripletMetricsCard lastDays={30} />
        {/* Recent Conversations */}
        <Card className="my-8">
          <CardHeader className="flex flex-row items-center justify-between">
            <CardTitle>Recent Conversations</CardTitle>
            <Link
              to="./conversations"
              className="inline-flex items-center text-sm text-primary hover:underline"
            >
              View All
              <ArrowUpRight className="ml-1 h-4 w-4" />
            </Link>
          </CardHeader>
          <CardContent>
            <ConversationsMetadataListV2
              projectId={project.id}
              since={new Date(since)}
              until={new Date(until)}
              aliases={searchParams.getAll("alias")}
            />
          </CardContent>
        </Card>

        <div className="h-12" />
      </div>
    </div>
  );
}
