import React from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import {
  Mail,
  Phone,
  Check,
  Globe,
  Smartphone,
  Laptop,
  Monitor,
  Tablet,
  Activity,
} from "lucide-react";
import { formatDistanceToNowStrict } from "date-fns";
import { FrameSessionRead } from "@/client/types.gen";
import { findClosestLanguageMatch, LanguageInfo } from "@/ietf-bcp-47";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";

const formatLastSeen = (date: Date) => {
  return new Intl.DateTimeFormat("default", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }).format(new Date(date));
};

const getInitials = (
  firstName: string | null | undefined,
  lastName: string | null | undefined
) => {
  if (!firstName && !lastName) return "?";
  return `${(firstName?.[0] || "").toUpperCase()}${(lastName?.[0] || "").toUpperCase()}`;
};

const getDeviceInfo = (userAgent: string | null) => {
  if (!userAgent) return { type: "Unknown", icon: Monitor };

  const ua = userAgent.toLowerCase();

  if (ua.includes("iphone")) {
    return { type: "iPhone", icon: Smartphone };
  } else if (ua.includes("ipad")) {
    return { type: "iPad", icon: Tablet };
  } else if (
    ua.includes("android") &&
    (ua.includes("mobile") || ua.includes("phone"))
  ) {
    return { type: "Android Phone", icon: Smartphone };
  } else if (ua.includes("android")) {
    return { type: "Android Tablet", icon: Tablet };
  } else if (ua.includes("mobile")) {
    return { type: "Mobile Device", icon: Smartphone };
  }
  return { type: "Desktop", icon: Laptop };
};

const ContactItem = ({
  icon: Icon,
  value,
  verified,
  label,
}: {
  icon: React.ElementType;
  value: string;
  verified: boolean;
  label: string;
}) => (
  <div className="flex items-center space-x-2 p-2 rounded-lg bg-muted/30">
    <Icon className="h-4 w-4 text-primary" />
    <div className="flex-1 min-w-0">
      <p className="text-xs text-muted-foreground">{label}</p>
      <p className="text-sm truncate">{value}</p>
    </div>
    {verified && (
      <TooltipProvider>
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            <div className="p-1 bg-blue-500 rounded-full flex items-center justify-center">
              <Check className="h-3 w-3 text-white" />
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <p>Verified</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )}
  </div>
);

export default function FrameSessionCard({
  frameSession,
}: {
  frameSession: FrameSessionRead;
}) {
  const { user, devices } = frameSession;
  const fullName =
    `${user.first_name || ""} ${user.last_name || ""}`.trim() ||
    "Anonymous User";

  const processedLocales = user.locales
    .map((locale) => ({
      locale,
      info: findClosestLanguageMatch(locale),
    }))
    .filter(
      (entry): entry is { locale: string; info: LanguageInfo } =>
        entry.info != null
    )
    .filter(
      (entry, index, array) =>
        index === array.findIndex((e) => e.info.code === entry.info.code)
    )
    .sort((a, b) => {
      const nameA = a.info.shortLocalizedName || a.locale;
      const nameB = b.info.shortLocalizedName || b.locale;
      return nameA.localeCompare(nameB);
    });

  return (
    <Card className="w-full bg-gradient-to-b from-background to-muted/20">
      <CardContent className="pt-6 px-4 pb-4">
        <div className="flex flex-col items-center space-y-4 mb-6">
          <Avatar className="h-14 w-14 ring-2 ring-primary/40">
            <AvatarFallback className="bg-gradient-to-br from-primary/20 to-primary/10 text-primary text-xl font-medium">
              {getInitials(user.first_name, user.last_name)}
            </AvatarFallback>
          </Avatar>

          <div className="text-center">
            <h2 className="text-lg font-semibold">{fullName}</h2>
            <div className="flex items-center justify-center gap-1 text-sm text-muted-foreground mt-1">
              <Globe className="h-3 w-3" />
              <span className="text-xs">{user.timezone}</span>
            </div>
            <div className="flex items-center justify-center gap-1 text-sm text-muted-foreground mt-1">
              <Activity className="h-3 w-3" />
              <span className="text-xs">
                Seen{" "}
                {formatDistanceToNowStrict(new Date(user.last_seen_at), {
                  addSuffix: true,
                })}
              </span>
            </div>
          </div>
        </div>

        <div className="space-y-3">
          {user.email && (
            <ContactItem
              icon={Mail}
              value={user.email}
              verified={user.email_signed || false}
              label="Email Address"
            />
          )}

          {user.phone && (
            <ContactItem
              icon={Phone}
              value={user.phone}
              verified={user.phone_signed || false}
              label="Phone Number"
            />
          )}
        </div>

        {processedLocales.length > 0 && (
          <div className="mt-4">
            <h3 className="text-xs font-medium text-muted-foreground mb-2">
              Languages
            </h3>
            <div className="flex flex-wrap gap-1">
              {processedLocales.map(({ locale, info }) => (
                <Badge key={locale} variant="secondary" className="text-xs">
                  {info.emoji} {info.shortLocalizedName || locale}
                </Badge>
              ))}
            </div>
          </div>
        )}

        {devices.length > 0 && (
          <div className="mt-4">
            <h3 className="text-xs font-medium text-muted-foreground mb-2">
              Devices
            </h3>
            <div className="space-y-2">
              {devices.map((device) => {
                const deviceInfo = getDeviceInfo(device.user_agent);
                const DeviceIcon = deviceInfo.icon;
                return (
                  <div
                    key={device.id}
                    className="flex items-center gap-2 text-xs p-2 rounded-lg bg-muted/30"
                  >
                    <DeviceIcon className="h-4 w-4 text-muted-foreground" />
                    <div className="flex-1 min-w-0">
                      <p className="font-medium">{deviceInfo.type}</p>
                      <p className="text-muted-foreground truncate">
                        Last seen{" "}
                        {formatLastSeen(new Date(device.last_seen_at))}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {user.external_attributes &&
          Object.keys(user.external_attributes).length > 0 && (
            <div className="mt-4">
              <h3 className="text-xs font-medium text-muted-foreground mb-2">
                Additional Info
              </h3>
              <div className="space-y-1">
                {Object.entries(user.external_attributes).map(
                  ([key, value]) => (
                    <div
                      key={key}
                      className="flex justify-between text-xs p-2 rounded-lg bg-muted/30"
                    >
                      <span className="text-muted-foreground">{key}</span>
                      <span>{String(value)}</span>
                    </div>
                  )
                )}
              </div>
            </div>
          )}
      </CardContent>
    </Card>
  );
}
