import { useParams } from "react-router-dom";
import {
  usePollPendingTestSuiteRuns,
  useTestCasesByTestSuite,
  useTestSuiteRunsByTestSuite,
  useTestSuites,
} from "../../hooks";
import Spinner from "../Loading";
import TestSuiteRunTable from "./TestSuiteRunTable";

import { EvaluationBreadcrumbsNavbar } from "../BreadcrumbNav";
import WithTestSuiteSidebar from "./WithTestSuiteSidebar";

export default function TestSuiteRunsPage() {
  const { projectId, testSuiteId } = useParams();
  const { testSuites, status, errorMessage } = useTestSuites({
    project_id: Number(projectId),
  });

  const testSuite = testSuites.find((ts) => ts.id == testSuiteId);

  if (projectId === undefined)
    throw new Error("Logical Error: projectId is undefined");
  if (testSuiteId === undefined)
    throw new Error("Logical Error: testSuiteId is undefined");

  const {
    testSuiteRuns,
    status: testSuiteRunsStatus,
    errorMessage: testSuiteRunErrorMessage,
  } = useTestSuiteRunsByTestSuite({
    project_id: Number(projectId),
    testSuiteId,
  });
  const {
    testCases,
    status: testCasesStatus,
    errorMessage: testCasesErrorMessage,
  } = useTestCasesByTestSuite({ project_id: Number(projectId), testSuiteId });

  usePollPendingTestSuiteRuns({ project_id: Number(projectId) });

  if (status === "loading") return <Spinner />;
  if (testSuite == undefined)
    return <p className="text-red-500">Error: {errorMessage}</p>;
  if (testSuiteRunsStatus === "loading") return <Spinner />;
  if (testCasesStatus === "loading") return <Spinner />;
  if (testSuiteRunsStatus === "failed")
    return <p className="text-red-500">Error: {testSuiteRunErrorMessage}</p>;
  if (testCasesStatus === "failed")
    return <p className="text-red-500">Error: {testCasesErrorMessage}</p>;

  return (
    <WithTestSuiteSidebar
      projectId={Number(projectId)}
      selectedTestSuiteId={testSuiteId}
    >
      <EvaluationBreadcrumbsNavbar
        items={[
          { href: "./../..", label: "Test Suites" },

          {
            href: "./..",
            label: testSuite.name,
          },
          { label: "Test Suite Runs" },
        ]}
      />
      <div className="flex items-center justify-between">
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
          {testSuite.name}
        </h1>
      </div>
      <p className="mt-2 min-h-[1rem]">{testSuite.description}</p>
      <TestSuiteRunTable
        testSuiteRuns={testSuiteRuns}
        createButtonEnabled={testCases.length > 0}
        pageSize={10}
      />
    </WithTestSuiteSidebar>
  );
}
