import React, { useEffect, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FrameSettingsSchema } from "@/lib/schemas";
import { z } from "zod";
import { Button } from "../ui/button";
import { Form } from "../ui/form";
import { GeneralSettings } from "./GeneralSettings";
import { ThemeSettings } from "./ThemesSettings";
import LauncherSettings from "./LauncherSettings";
import {
  Settings2,
  Palette,
  Rocket,
  Save,
  X,
  LucideMessageSquare,
  Loader2,
  Code,
} from "lucide-react";
import LocalizationSettings from "./LocalizationSettings";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getAliasOptions,
  getFrameOptions,
  updateFrameMutation,
} from "@/client/@tanstack/react-query.gen";
import { useParams } from "react-router-dom";
import { AxiosErrorBox } from "../Error";
import { getErrorMessages } from "@/lib/utils";
import Spinner from "../Loading";
import { useToast } from "../ui/use-toast";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
import { PreviewPanel } from "./PreviewPanel";
import { FrameEditorProvider } from "./FrameEditorContext";
import IntegrationTab from "./IntegrationTab";
import { BreadcrumbNavigation } from "../BreadcrumbNav";
import NavigationBlockerDialog from "../NavigationBlockerDialog";
import { useSearchParams } from "react-router-dom";

export type FrameSettingsValues = z.infer<typeof FrameSettingsSchema>;

const TabIcon = ({ children }: { children: React.ReactNode }) => (
  <div className="flex items-center gap-2">{children}</div>
);

const useEditorTabs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultTab = "code";
  const tabs = ["general", "theme", "localization", "launcher", "code"];
  const tab = tabs.includes(searchParams.get("tab") || "")
    ? searchParams.get("tab")!
    : defaultTab;
  const setTab = (value: string) =>
    setSearchParams(
      (prev) => {
        const newParams = new URLSearchParams(prev);
        newParams.set("tab", value);
        return newParams;
      },
      { replace: true }
    );
  return [tab, setTab] as const;
};

const FrameSettingsEditor = () => {
  const { projectId, frameId } = useParams();
  const { toast } = useToast();
  const [tab, setTab] = useEditorTabs();

  const { data: frame, isLoading: isFrameLoading } = useQuery({
    ...getFrameOptions({
      path: {
        project_id: Number(projectId),
        frame_id: frameId!,
      },
    }),
  });

  const { data: deploymentAlias, error: aliasError } = useQuery({
    ...getAliasOptions({
      path: {
        project_id: Number(projectId),
        alias_id: frame?.alias_id ?? "",
      },
    }),
    enabled: !!frame,
  });

  useEffect(() => {
    (window.$crisp as Window["$crisp"]).push(["do", "chat:hide"]);
    return () => {
      (window.$crisp as Window["$crisp"]).push(["do", "chat:show"]);
    };
  }, []);

  const queryClient = useQueryClient();

  const updateFrame = useMutation({
    ...updateFrameMutation(),
    onSuccess: () => {
      toast({
        title: "Settings updated",
        description: "Applying changes may take a moment.",
      });
      queryClient.invalidateQueries({
        queryKey: getFrameOptions({
          path: {
            project_id: Number(projectId),
            frame_id: frameId!,
          },
        }).queryKey,
      });
    },
    onError: (error) => {
      toast({
        title: "Updating settings failed",
        description: getErrorMessages(error).join(", "),
        variant: "destructive",
      });
    },
  });

  const form = useForm<FrameSettingsValues>({
    resolver: zodResolver(FrameSettingsSchema),
    mode: "onChange",
    defaultValues: undefined,
  });

  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    if (frame?.settings && !isInitialized) {
      form.reset(structuredClone(frame.settings));
      setIsInitialized(true);
    }
  }, [frame, form, isInitialized]);

  function onSubmit(values: FrameSettingsValues) {
    if (!frame) {
      console.error("No frame data available");
      return;
    }

    updateFrame.mutate({
      body: {
        name: frame.name,
        description: frame.description,
        alias_id: frame.alias_id,
        user_pool_id: frame.user_pool_id,
        settings: values,
      },
      path: {
        project_id: Number(projectId),
        frame_id: frame.id,
      },
    });
    // NOTE(memben): Reset to mark as not dirty
    form.reset(values, { keepValues: true });
  }

  if (aliasError) {
    return <AxiosErrorBox error={aliasError} />;
  }

  if (isFrameLoading || !frame) return <Spinner />;

  if (form.formState === undefined) return <Spinner />;

  const isPreviewInteractive =
    deploymentAlias?.access_control_list.gate.challenge === "none";

  const isSubmitting = updateFrame.isPending;
  const isFormValid = form.formState.isValid;
  const isSaveDisabled = isSubmitting || !isFormValid;

  const tabsTriggerClassName =
    "rounded-md px-2 md:px-4 py-2 transition-all data-[state=active]:bg-white data-[state=active]:text-blue-600 data-[state=active]:shadow-sm text-xs md:text-md";
  return (
    <FrameEditorProvider frame={frame}>
      <div className="flex min-h-screen flex-col lg:flex-row">
        <div className="w-full lg:w-3/5">
          <main className="mx-1 max-w-3xl sm:mx-auto">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-6"
              >
                <div className="p-6">
                  <BreadcrumbNavigation
                    items={[
                      { href: `./..`, label: "Frames" },
                      { label: frame.name },
                    ]}
                  />
                  <Tabs
                    value={tab}
                    onValueChange={setTab}
                    className="mt-4 space-y-6"
                  >
                    <div className="flex justify-center">
                      <TabsList className="rounded-lg bg-gray-100/50 p-1">
                        <TabsTrigger
                          value="general"
                          className={tabsTriggerClassName}
                        >
                          <TabIcon>
                            <Settings2 className="hidden h-4 w-4 md:block" />
                            <span>General</span>
                          </TabIcon>
                        </TabsTrigger>
                        <TabsTrigger
                          value="theme"
                          className={tabsTriggerClassName}
                        >
                          <TabIcon>
                            <Palette className="hidden h-4 w-4 md:block" />
                            <span>Theme</span>
                          </TabIcon>
                        </TabsTrigger>
                        <TabsTrigger
                          value="localization"
                          className={tabsTriggerClassName}
                        >
                          <TabIcon>
                            <LucideMessageSquare className="hidden h-4 w-4 md:block" />
                            <span>Messages</span>
                          </TabIcon>
                        </TabsTrigger>
                        <TabsTrigger
                          value="launcher"
                          className={tabsTriggerClassName}
                        >
                          <TabIcon>
                            <Rocket className="hidden h-4 w-4 md:block" />
                            <span>Launcher</span>
                          </TabIcon>
                        </TabsTrigger>
                        <TabsTrigger
                          value="code"
                          className={tabsTriggerClassName}
                        >
                          <TabIcon>
                            <Code className="hidden h-4 w-4 md:block" />
                            <span>Code</span>
                          </TabIcon>
                        </TabsTrigger>
                      </TabsList>
                    </div>

                    <div className="mt-6">
                      <TabsContent
                        value="general"
                        className="mt-0 focus-visible:outline-none focus-visible:ring-0"
                      >
                        <GeneralSettings />
                      </TabsContent>
                      <TabsContent
                        value="theme"
                        className="mt-0 focus-visible:outline-none focus-visible:ring-0"
                      >
                        <ThemeSettings />
                      </TabsContent>
                      <TabsContent
                        value="localization"
                        className="mt-0 focus-visible:outline-none focus-visible:ring-0"
                      >
                        <LocalizationSettings />
                      </TabsContent>
                      <TabsContent
                        value="launcher"
                        className="mt-0 focus-visible:outline-none focus-visible:ring-0"
                      >
                        <LauncherSettings />
                      </TabsContent>
                      <TabsContent
                        value="code"
                        className="mt-0 focus-visible:outline-none focus-visible:ring-0"
                      >
                        <IntegrationTab frame={frame} />
                      </TabsContent>
                    </div>
                  </Tabs>
                </div>
                <div className="flex w-full justify-end gap-3 border-t border-gray-100 p-6">
                  <Button
                    variant="outline"
                    type="reset"
                    size="sm"
                    className="text-gray-600 hover:text-gray-700"
                    onClick={() => form.reset(structuredClone(frame.settings))}
                    disabled={isSubmitting}
                  >
                    <X className="mr-2 h-4 w-4" />
                    Cancel
                  </Button>
                  <TooltipProvider>
                    <Tooltip delayDuration={200}>
                      <TooltipTrigger asChild>
                        <Button
                          type="submit"
                          size="sm"
                          className="bg-blue-500 text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:bg-blue-300 disabled:opacity-50"
                          disabled={isSaveDisabled}
                        >
                          {isSubmitting ? (
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                          ) : (
                            <Save className="mr-2 h-4 w-4" />
                          )}
                          {isSubmitting ? "Saving..." : "Save Changes"}
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        {isFormValid ? (
                          <div className="text-sm">
                            Applying changes may take a moment.
                          </div>
                        ) : (
                          <div className="text-sm text-red-500">
                            Please fix form errors before saving
                          </div>
                        )}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </form>
            </Form>
          </main>
        </div>

        <PreviewPanel
          frameId={isPreviewInteractive ? frame.id : undefined}
          frameSettings={form.getValues()}
          isFormValid={isFormValid}
        />
      </div>
      <NavigationBlockerDialog isDirty={form?.formState.isDirty} />
    </FrameEditorProvider>
  );
};

export default FrameSettingsEditor;
