import { HTTPValidationError } from "@/client/types.gen";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { AxiosError } from "axios";
import { z } from "zod";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const stringErrorMessage = (
  error: AxiosError<HTTPValidationError>
): string => {
  const messages = error.response?.data?.errors?.map((err) => err.message) || [
    error.message || "An error occurred",
  ];
  return messages.length === 1 ? messages[0] : messages.join(", ");
};

export const getErrorMessages = (
  error: AxiosError<HTTPValidationError, any>
): string[] => {
  return (
    error.response?.data?.errors?.map((err) => err.message) || [
      error.message || "An error occurred",
    ]
  );
};

/**
 * Converts an object to URL-safe query parameters
 */
export function encodeParams<T extends Record<string, any>>(obj: T): string {
  // Handle nested objects and arrays by stringifying them
  const processedObj = Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      typeof value === "object" ? JSON.stringify(value) : value,
    ])
  );

  const params = new URLSearchParams(processedObj);
  return params.toString();
}

/**
 * Decodes URL query parameters into an object and validates against a schema
 */
export function decodeParams<T>(
  params: URLSearchParams,
  schema: z.ZodType<T>
): T | null {
  try {
    const obj: Record<string, any> = {};

    // Convert params to object, attempting to parse JSON strings
    for (const [key, value] of params.entries()) {
      try {
        // Attempt to parse as JSON for nested objects/arrays
        obj[key] = JSON.parse(value);
      } catch {
        // If not valid JSON, use the raw value
        obj[key] = value;
      }
    }

    console.log("Decoded params:", obj);

    return schema.parse(obj);
  } catch (e) {
    console.error("Error decoding params:", e);
    return null;
  }
}
