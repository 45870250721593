import { configureStore } from "@reduxjs/toolkit";
import projectsReducer from "./slices/projects";
import metricsReducer from "./slices/metrics";
import deploymentsReducer from "./slices/deployments";
import evaluationReducer from "./slices/evaluations";

const store = configureStore({
  reducer: {
    // auth: authReducer,
    projects: projectsReducer,
    metrics: metricsReducer,
    deployments: deploymentsReducer,
    evaluations: evaluationReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
