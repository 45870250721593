import {
  SearchSelect,
  SearchSelectItem,
  Select,
  SelectItem,
  TextInput,
  Dialog,
  DialogPanel,
} from "@tremor/react";
import LinkWithQuery from "./LinkWithQuery";
import { formatDistanceToNowStrict } from "date-fns";
import Empty from "./Empty";
import { useCurrentProject } from "@/hooks";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Spinner from "./Loading";
import {
  HandThumbUpIcon,
  CheckCircleIcon,
  FaceSmileIcon,
  HandThumbDownIcon,
  HeartIcon,
  FaceFrownIcon,
  UserCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  HandRaisedIcon,
  RocketLaunchIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon, LanguageIcon } from "@heroicons/react/24/solid";
import QueryDateRangePicker from "./QueryDateRangePicker";
import { LANGUAGES_LIST } from "@/iso639-1";
import QueryDeploymentAliasPicker from "./QueryDeploymentAliasPicker";
import { Card, CardContent, CardHeader } from "./ui/card";
import { Bot, User } from "lucide-react";
import { MessageRead } from "@/client/types.gen";

function MessageList({ messages }: { messages: MessageRead[] }) {
  if (messages.length === 0) {
    return (
      <div className="mt-12">
        <Empty message="No messages found." />
      </div>
    );
  }

  const sortedMessages = [...messages].sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );

  return (
    <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2 lg:grid-cols-3">
      {sortedMessages.map((message) => (
        <LinkWithQuery
          to={`/${message.project_id}/conversations/${message.conversation_id}?message_id=${message.id}`}
          key={message.id}
          className="no-underline"
        >
          <Card className="h-full transition-all hover:shadow-md">
            <CardHeader className="flex-row items-center justify-between space-y-0 pb-2">
              <div className="flex items-center space-x-2 text-sm text-muted-foreground">
                <time>
                  {formatDistanceToNowStrict(new Date(message.created_at), {
                    addSuffix: true,
                  })}
                </time>
                <span>•</span>
                <div className="flex items-center space-x-1">
                  {message.role === "user" ? (
                    <User className="h-4 w-4 text-blue-500" />
                  ) : (
                    <Bot className="h-4 w-4 text-emerald-600" />
                  )}
                  <span className="capitalize">{message.role}</span>
                </div>
              </div>
            </CardHeader>
            <CardContent>
              <p
                className={`line-clamp-3 ${
                  message.role === "user"
                    ? "text-sm font-medium"
                    : "text-sm text-muted-foreground"
                }`}
              >
                {message.text}
              </p>
            </CardContent>
          </Card>
        </LinkWithQuery>
      ))}
    </div>
  );
}
function SearchSpinner() {
  return (
    <div className="flex items-center justify-center">
      <div role="status">
        <svg
          aria-hidden="true"
          className="tremor-TextInput-icon absolute left-0 top-2 mx-2.5 flex h-5 w-5 shrink-0 animate-spin items-center fill-emerald-600 text-gray-200 dark:text-gray-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

interface EndpointRequest {
  project_id: number;
  since: string;
  until: string;
  text: string;
  aliases: string[];
  tags: {
    name: string;
    value: string;
  }[];
  limit: number;
  offset: number;
}

function MessageSearchPage() {
  const { project } = useCurrentProject();
  const [searchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const since = searchParams.get("since");
  const until = searchParams.get("until");

  const [fullTextSearch, setFullTextSearch] = useState<string | undefined>(
    searchParams.get("query") || undefined
  );

  const [resolutionValue, setResolutionValue] = useState<string | undefined>(
    searchParams.get("completeness") || undefined
  );
  const [ratingValue, setRatingValue] = useState<string | undefined>(
    searchParams.get("rating")?.replace("unrated", "") || undefined
  );

  const [sentimentValue, setSentimentValue] = useState<string | undefined>(
    searchParams.get("sentiment") || undefined
  );

  const [languageValue, setLanguageValue] = useState<string | undefined>(
    searchParams.get("language") || undefined
  );

  // Debounce state for fullTextSearch
  const [debouncedFullTextSearch, setDebouncedFullTextSearch] =
    useState(fullTextSearch);
  const [isDebouncing, setIsDebouncing] = useState(false);

  // State for API call
  const [messages, setMessages] = useState<MessageRead[]>([]);
  const [status, setStatus] = useState<string>("idle");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    setIsDebouncing(true);
    const handler = setTimeout(() => {
      setDebouncedFullTextSearch(fullTextSearch);
      setIsDebouncing(false);
    }, 500);

    return () => {
      clearTimeout(handler);
      setIsDebouncing(false);
    };
  }, [fullTextSearch]);

  useEffect(() => {
    const fetchData = async () => {
      if (!project || !since || !until) return;
      setStatus("loading");
      setErrorMessage(null);
      const apiToken = await (window as any).Clerk.session.getToken({
        template: "api_botbrains_io",
      });
      const requestBody: EndpointRequest = {
        project_id: project.id,
        since: since || "",
        until: until || "",
        text: debouncedFullTextSearch || "",
        aliases: searchParams.getAll("alias"),
        tags: [
          ["nlp:assistant.completeness", resolutionValue],
          ["chat:rating", ratingValue],
          ["nlp:user.sentiment", sentimentValue],
          ["nlp:user.language", languageValue],
        ]
          .filter(([, value]) => !!value)
          .map(([name, value]) => ({ name, value })) as {
          name: string;
          value: string;
        }[],
        limit: 200,
        offset: 0,
      };

      try {
        const response = await fetch(
          `${import.meta.env.VITE_API_BASE_URL}/v1/projects/${project.id}/messages-search`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${apiToken}`,
            },
            body: JSON.stringify(requestBody),
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setMessages(data);
        setStatus("succeeded");
      } catch (error) {
        setErrorMessage((error as any).message);
        setStatus("failed");
      }
    };
    fetchData();
  }, [
    project,
    since,
    until,
    debouncedFullTextSearch,
    searchParams,
    resolutionValue,
    ratingValue,
    sentimentValue,
    languageValue,
  ]);

  if (project === undefined) return <Spinner />;
  const resolutionIcons = (v: string | undefined) =>
    v
      ? {
          Complete: CheckCircleIcon,
          Partial: ExclamationCircleIcon,
          Incomplete: ExclamationTriangleIcon,
        }[v]
      : HandRaisedIcon;

  const ratingIcons = (v: string | undefined) =>
    v
      ? {
          thumbsUp: HandThumbUpIcon,
          thumbsDown: HandThumbDownIcon,
        }[v]
      : HeartIcon;

  const sentimentIcons = (v: string | undefined) =>
    v
      ? {
          Positive: FaceSmileIcon,
          Neutral: UserCircleIcon,
          Negative: FaceFrownIcon,
        }[v]
      : UserCircleIcon;

  return (
    <div className="pt-8">
      <div className="mx-1 max-w-7xl sm:mx-auto sm:px-6 lg:px-8">
        <header>
          <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
            Search Messages
          </h1>
          <p className="mt-4 text-sm text-gray-500">
            Deeply search messages to find relevant conversations with full text
            search and filters.
          </p>
        </header>
        <main>
          <div className="mt-4 p-2">
            <div className="space flex w-full flex-row flex-wrap justify-between gap-4 sm:flex-nowrap">
              <div className="flex w-full flex-row gap-2">
                <TextInput
                  icon={isDebouncing ? SearchSpinner : MagnifyingGlassIcon}
                  placeholder="Search..."
                  value={fullTextSearch}
                  onValueChange={setFullTextSearch}
                  className="lg:w-96"
                />
                <div className="flex justify-center">
                  <button
                    className="flex items-center justify-center "
                    onClick={() => setIsOpen(true)}
                  >
                    <InformationCircleIcon className="h-5 w-5 text-gray-400 hover:text-emerald-600" />
                  </button>
                  <Dialog
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    static={true}
                    className="z-[100]"
                  >
                    <DialogPanel className="max-w-md">
                      <RocketLaunchIcon className="h-6 w-6" />
                      <h2 className="text-lg font-semibold">
                        Full Text Search
                      </h2>
                      <p className="mt-2">
                        Our search supports advanced full-text search
                        capabilities. Here are some examples of how you can use
                        it:
                      </p>
                      <ul className="mt-2 list-inside space-y-2">
                        <li>
                          <strong>star wars</strong>
                          <p className="text-sm">
                            Search for messages containing both "star" and
                            "wars".
                          </p>
                        </li>
                        <li>
                          <strong>star or wars</strong>
                          <p className="text-sm">
                            Search for messages containing either "star" or
                            "wars".
                          </p>
                        </li>
                        <li>
                          <strong>star -wars</strong>
                          <p className="text-sm">
                            Search for messages containing "star" but not
                            "wars".
                          </p>
                        </li>
                        <li>
                          <strong>"star wars"</strong>
                          <p className="text-sm">
                            Search for messages containing the exact phrase
                            "star wars".
                          </p>
                        </li>
                      </ul>
                      <button
                        className="mt-4 flex w-full items-center justify-center rounded-lg bg-emerald-600 p-2 font-bold text-white hover:bg-emerald-700"
                        onClick={() => setIsOpen(false)}
                      >
                        Close
                      </button>
                    </DialogPanel>
                  </Dialog>
                </div>
              </div>

              <div className="w-full sm:w-auto">
                <QueryDateRangePicker />
              </div>
            </div>
            <div className=" mt-4 flex flex-row gap-4">
              <div className="grid auto-rows-fr grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-5">
                <QueryDeploymentAliasPicker />
                <Select
                  placeholder="Resolution Status"
                  value={resolutionValue}
                  onValueChange={setResolutionValue}
                  enableClear={true}
                  icon={resolutionIcons(resolutionValue)}
                >
                  <SelectItem
                    value="Complete"
                    icon={resolutionIcons("Complete")}
                  >
                    Resolved
                  </SelectItem>
                  <SelectItem value="Partial" icon={resolutionIcons("Partial")}>
                    Partially Resolved
                  </SelectItem>
                  <SelectItem
                    value="Incomplete"
                    icon={resolutionIcons("Incomplete")}
                  >
                    Incomplete
                  </SelectItem>
                </Select>
                <Select
                  placeholder="All Ratings"
                  value={ratingValue}
                  onValueChange={setRatingValue}
                  enableClear={true}
                  icon={ratingIcons(ratingValue)}
                >
                  <SelectItem value="thumbsUp" icon={ratingIcons("thumbsUp")}>
                    Liked
                  </SelectItem>
                  <SelectItem
                    value="thumbsDown"
                    icon={ratingIcons("thumbsDown")}
                  >
                    Disliked
                  </SelectItem>
                </Select>
                <Select
                  placeholder="All Sentiments"
                  value={sentimentValue}
                  onValueChange={setSentimentValue}
                  enableClear={true}
                  icon={sentimentIcons(sentimentValue)}
                >
                  <SelectItem
                    value="Positive"
                    icon={sentimentIcons("Positive")}
                  >
                    Positive Sentiment
                  </SelectItem>
                  <SelectItem value="Neutral" icon={sentimentIcons("Neutral")}>
                    Neutral Sentiment
                  </SelectItem>
                  <SelectItem
                    value="Negative"
                    icon={sentimentIcons("Negative")}
                  >
                    Negative Sentiment
                  </SelectItem>
                </Select>
                <SearchSelect
                  placeholder="All Languages"
                  value={languageValue}
                  onValueChange={setLanguageValue}
                  enableClear={true}
                  icon={LanguageIcon}
                >
                  {Object.entries(LANGUAGES_LIST).map(([iso639, language]) => (
                    <SearchSelectItem key={iso639} value={iso639}>
                      {language.name}
                    </SearchSelectItem>
                  ))}
                </SearchSelect>
              </div>
            </div>
            {since && until && <MessageList messages={messages} />}
            {status === "loading" && <Spinner />}
            {status === "failed" && (
              <div className="text-red-500">{errorMessage}</div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default MessageSearchPage;
