import TestCaseForm, {
  TestCaseSubmit,
} from "@/components/evaluation/TestCaseForm";
import { useAppDispatch, useTestCase, useTestSuites } from "@/hooks";
import { updateTestCase } from "@/slices/evaluations";
import { useParams } from "react-router-dom";
import CenteredLayout from "../CenteredLayout";
import Spinner from "../Loading";
import { EvaluationBreadcrumbsNavbar } from "../BreadcrumbNav";

function TestCaseEditPage() {
  const { projectId, testSuiteId, testCaseId } = useParams();
  const dispatch = useAppDispatch();

  if (!testSuiteId || !testCaseId) {
    throw new Error("Logical Error: testsuite_id is required");
  }

  const { testCase, status, errorMessage } = useTestCase({
    project_id: Number(projectId),
    testSuiteId,
    testCaseId,
  });

  const { testSuites } = useTestSuites({ project_id: Number(projectId) });
  const testSuite = testSuites.find((t) => t.id === testSuiteId);

  if (testCase == undefined) return <p>Test Case not found</p>;

  if (status == "loading") return <Spinner />;
  if (status == "failed")
    return <p className="text-red-500">Error: {errorMessage}</p>;

  const handleCreateTestCase = async (values: TestCaseSubmit) =>
    await dispatch(updateTestCase(values));
  return (
    <CenteredLayout>
      <EvaluationBreadcrumbsNavbar
        items={[
          { href: "./../../../..", label: "Test Suites" },
          {
            href: "./../../..",
            label: testSuite?.name ?? "Test Suite",
          },
          {
            label: "Test Cases",
          },
          {
            href: "./..",
            label: testCase.name,
          },
          {
            href: ".",
            label: "Edit",
          },
        ]}
      />
      <TestCaseForm
        initialValues={testCase}
        handleSubmitAsync={handleCreateTestCase}
      />
    </CenteredLayout>
  );
}

export default TestCaseEditPage;
