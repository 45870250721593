import { lazy, Suspense, useState } from "react";
import { Code, Check, Copy, AlertCircle } from "lucide-react";
import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { FrameRead } from "@/client";
import { useSearchParams } from "react-router-dom";
import PreviewUrl from "../PreviewUrl";

const SyntaxHighlighter = lazy(() => import("../SyntaxHighlighter"));

function CodeBlockWithCopy({ code }: { code: string }) {
  const [copySuccess, setCopySuccess] = useState("");

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(code);
      setCopySuccess("Copied!");
      setTimeout(() => setCopySuccess(""), 2000);
    } catch (err) {
      setCopySuccess("Failed to copy!");
      setTimeout(() => setCopySuccess(""), 2000);
    }
  };

  return (
    <div className="relative rounded-lg bg-slate-800 p-4">
      <pre className="max-h-[400px] overflow-x-auto overflow-y-auto text-sm text-slate-50">
        <Suspense fallback={<code>{code}</code>}>
          <SyntaxHighlighter language="jsx" code={code} />
        </Suspense>
      </pre>
      <div className="absolute right-4 top-4 flex items-center gap-2">
        {copySuccess && (
          <span className="text-sm text-green-400">{copySuccess}</span>
        )}
        <Button
          onClick={copyToClipboard}
          variant="ghost"
          type="button"
          size="sm"
          className="text-sm font-medium text-slate-200 hover:bg-slate-700 hover:text-slate-50"
        >
          {copySuccess ? (
            <Check className="mr-2 h-4 w-4" />
          ) : (
            <Copy className="mr-2 h-4 w-4" />
          )}
          Copy Snippet
        </Button>
      </div>
    </div>
  );
}

const IntegrationTab = ({ frame }: { frame: FrameRead }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const integration = searchParams.get("integration") || "launcher";

  const embedCode = `<script type="text/javascript">
  $botbrains = [];
  $botbrains.push(["set", "config", {
      frameId: '${frame.id}',
  }]);
  (function(){d = document;s = d.createElement('script');s.src = '${import.meta.env.VITE_CHAT_URL}/loader.js';s.type = 'module';s.async = 1;d.getElementsByTagName('head')[0].appendChild(s);})();
</script>`;

  const iframeCode = `<iframe 
  src="${import.meta.env.VITE_CHAT_URL}/loader?frameId=${frame.id}&mode=fullscreen" 
  width="100%" 
  height="100%" 
  style="border: none; min-height: 500px;"
  allow="fullscreen; clipboard-write; clipboard-read"
  frameborder="0"
/>`;

  const handleTabChange = (value: string) => {
    setSearchParams(
      (prev) => {
        const newParams = new URLSearchParams(prev);
        newParams.set("integration", value);
        return newParams;
      },
      { replace: true }
    );
  };

  return (
    <Card className="w-full rounded-xl border border-gray-100 bg-white/80 shadow-none">
      <CardHeader className="border-b border-gray-100 pb-4">
        <div className="flex items-center gap-2">
          <Code className="h-5 w-5 text-blue-500" />
          <CardTitle className="text-lg font-semibold text-gray-800">
            Integration Options
          </CardTitle>
        </div>
      </CardHeader>
      <CardContent className="space-y-6 p-6">
        <Tabs
          value={integration}
          onValueChange={handleTabChange}
          className="w-full"
        >
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="launcher">Launcher</TabsTrigger>
            <TabsTrigger value="iframe">iFrame</TabsTrigger>
          </TabsList>

          <TabsContent value="launcher" className="mt-6">
            <Alert>
              <div className="flex items-center gap-2">
                <Check className="h-4 w-4 text-green-600" />
                <AlertTitle className="mb-0">Recommended</AlertTitle>
              </div>
              <AlertDescription className="ml-6 text-sm text-gray-700">
                Add this code snippet to embed the chat as a widget that appears
                in the corner of your page. It loads asynchronously and can be
                placed anywhere in your HTML.
              </AlertDescription>
            </Alert>
            <div className="mt-4">
              <CodeBlockWithCopy code={embedCode} />
            </div>
          </TabsContent>

          <TabsContent value="iframe" className="mt-6">
            <Alert>
              <div className="flex items-center gap-2">
                <AlertCircle className="h-4 w-4 text-red-600" />
                <AlertTitle className="mb-0 text-red-600">
                  Warning (deprecated)
                </AlertTitle>
              </div>
              <AlertDescription className="ml-6 text-sm text-gray-700">
                Use this code to embed the chat as a <strong>fullscreen</strong>{" "}
                iframe. You will not be able to use the Web SDK. Not all
                features of Users will work in this mode.
              </AlertDescription>
            </Alert>
            <div className="mt-4">
              <CodeBlockWithCopy code={iframeCode} />
            </div>
          </TabsContent>
        </Tabs>
        <PreviewUrl />
      </CardContent>
    </Card>
  );
};

export default IntegrationTab;
