import React from "react";
import TestSuiteSidebar from "./TestSuiteSidebar";

export default function WithTestSuiteSidebar({
  projectId,
  selectedTestSuiteId,
  children,
}: {
  projectId: number;
  selectedTestSuiteId: string | undefined;
  children: React.ReactNode;
}) {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="">
        <TestSuiteSidebar
          projectId={projectId}
          selectedTestSuiteId={selectedTestSuiteId}
        />
      </div>
      <main className="h-full w-full max-w-6xl pt-2 sm:px-6 lg:px-8">
        {children}
      </main>
    </div>
  );
}
