import { useAppDispatch, useTestSuites } from "@/hooks";
import { updateTestSuite } from "@/slices/evaluations";
import { useParams } from "react-router-dom";
import TestSuiteForm, { TestSuiteSubmit } from "./TestSuiteForm";
import { TestSuiteSchema } from "@/lib/schemas";
import CenteredLayout from "../CenteredLayout";
import Spinner from "../Loading";
import { EvaluationBreadcrumbsNavbar } from "../BreadcrumbNav";

function TestSuiteEditorPage() {
  const { projectId, testSuiteId } = useParams();
  const dispatch = useAppDispatch();

  const { testSuites, status, errorMessage } = useTestSuites({
    project_id: Number(projectId),
  });
  const testSuite = testSuites.find((testSuite) => testSuite.id == testSuiteId);

  if (testSuite == undefined) return <p>Test Suite not found</p>;

  const initialValues = TestSuiteSchema.parse(testSuite);

  if (status == "loading") return <Spinner />;
  if (status == "failed")
    return <p className="text-red-500">Error: {errorMessage}</p>;

  const handleCreateTestSuite = async (values: TestSuiteSubmit) =>
    await dispatch(updateTestSuite(values));
  return (
    <CenteredLayout>
      <EvaluationBreadcrumbsNavbar
        items={[
          { href: "./../..", label: "Test Suites" },
          { href: "./..", label: testSuite?.name ?? "Test Suite" },
          { label: "Edit" },
        ]}
      />
      <TestSuiteForm
        initialValues={initialValues}
        handleSubmitAsync={handleCreateTestSuite}
      />
    </CenteredLayout>
  );
}

export default TestSuiteEditorPage;
