"use client";

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

function DescribableFields({ form }: { form: any }) {
  return (
    <>
      <FormField
        control={form.control}
        name="name"
        render={({ field }: any) => (
          <FormItem>
            <FormLabel>Name</FormLabel>
            <FormControl>
              <Input placeholder="Enter a meaningful name" {...field} />
            </FormControl>
            <FormDescription>Enter a meaningful name.</FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="description"
        render={({ field }: any) => (
          <FormItem>
            <FormLabel>Description</FormLabel>
            <FormControl>
              <Input placeholder="Optionally enter a description" {...field} />
            </FormControl>
            <FormDescription>Provide a brief description.</FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
}

export default DescribableFields;
