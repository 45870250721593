import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Deployment } from "../structs";

interface DeploymentsState {
  deploymentsData: { [key: string]: Deployment[] };
  status: { [key: string]: "idle" | "loading" | "succeeded" | "failed" };
  errorMessage: { [key: string]: string | null };
}

const initialState: DeploymentsState = {
  deploymentsData: {},
  status: {},
  errorMessage: {},
};

interface FetchDeploymentsArgs {
  projectId: string;
}

function argsToId(args: FetchDeploymentsArgs): string {
  return `/v1/projects/${args.projectId}/deployments`;
}

// Async thunk for fetching deployments
export const fetchDeployments = createAsyncThunk(
  "deployments/fetchDeployments",
  async (args: FetchDeploymentsArgs, { rejectWithValue }) => {
    const apiToken = await (window as any).Clerk.session.getToken({
      template: "api_botbrains_io",
    });
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/v1/projects/${
          args.projectId
        }/deployments`,
        {
          headers: {
            authorization: `Bearer ${apiToken}`,
          },
        },
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return await response.json();
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
  {
    condition: (args: FetchDeploymentsArgs, { getState }) => {
      const { deployments } = getState() as RootState;
      const id = argsToId(args);
      if (
        deployments.status[id] === "loading" ||
        deployments.status[id] === "succeeded"
      ) {
        return false;
      }
    },
  },
);

export const deploymentsSlice = createSlice({
  name: "deployments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeployments.pending, (state, action) => {
        state.status[argsToId(action.meta.arg)] = "loading";
      })
      .addCase(fetchDeployments.fulfilled, (state, action) => {
        state.status[argsToId(action.meta.arg)] = "succeeded";
        state.deploymentsData[argsToId(action.meta.arg)] =
          action.payload.deployments;
      })
      .addCase(fetchDeployments.rejected, (state, action) => {
        state.status[argsToId(action.meta.arg)] = "failed";
        state.errorMessage[argsToId(action.meta.arg)] =
          action.payload as string;
      });
  },
});

// Selectors

const selectDeploymentsState = (state: RootState) => state.deployments;

export const selectDeploymentsData = (args: FetchDeploymentsArgs) =>
  createSelector(
    selectDeploymentsState,
    (deploymentsState) =>
      deploymentsState.deploymentsData[argsToId(args)] ?? [],
  );

export const selectDeploymentsStatus = (args: FetchDeploymentsArgs) =>
  createSelector(
    selectDeploymentsState,
    (deploymentsState) => deploymentsState.status[argsToId(args)] ?? "idle",
  );

export const selectDeploymentsError = (args: FetchDeploymentsArgs) =>
  createSelector(
    selectDeploymentsState,
    (deploymentsState) => deploymentsState.errorMessage[argsToId(args)] ?? null,
  );
export default deploymentsSlice.reducer;
