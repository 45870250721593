import { client } from "./client/services.gen";
import "@tanstack/react-query";

export const configureApiClient = async () => {
  client.setConfig({
    baseURL: import.meta.env.VITE_API_BASE_URL,
  });

  // Add interceptor for automatic token handling
  client.instance.interceptors.request.use(async (config) => {
    const platformToken = await (window as any).Clerk.session.getToken({
      template: "api_botbrains_io",
    });
    config.headers.Authorization = `Bearer ${platformToken}`;
    return config;
  });
};
