import { ReactNode, useState } from "react";
import { useCurrentProject, useMetric } from "../hooks";
import {
  BadgeDelta,
  BarChart,
  BarList,
  Bold,
  Card,
  DonutChart,
  Flex,
  Metric,
  Select,
  SelectItem,
  Text,
  Title,
} from "@tremor/react";

import {
  eachDayOfInterval,
  eachHourOfInterval,
  endOfDay,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
} from "date-fns";
import { UTCDateMini } from "@date-fns/utc";
import { log } from "../logger";
import { useNavigate, useParams } from "react-router-dom";
import { LANGUAGES_LIST } from "../iso639-1";
import React from "react";
import { useMetricSearchParams } from "./charts/MetricCards";

// https://flowbite.com/docs/plugins/charts/

// export function ChartUniqueUsers() {
//   const { filters, filterBy, range, setRange } = useMonthFilters(3, 92);
//   const { chartData, cumChartData, status } = useUniqueUserCountDaily(filterBy.since.toISOString(), filterBy.until.toISOString());
//   if (status === "loading") {
//     return <div className="h-full w-full animate-pulse rounded bg-gray-200" />;;
//   }
//   if (status === "failed" || status === "idle") {
//     return <div></div>;
//   }
//   console.log("ssss", chartData)
//   const sum = cumChartData.reduce((acc: number, v: any) => acc + v[range], 0);

//   return (
//     <Card>
//       <div className="flex flex-row justify-between w-full">
//         <Title>Unique Users</Title>
//         <SelectRange range={range} setRange={setRange} filters={filters} />
//       </div>
//       <Metric className="h-full">
//         <div className="h-full flex flex-col items-center justify-center">
//           <div className="text-4xl text-emerald-600">{sum}</div>
//         </div>
//       </Metric>
//     </Card>
//   );
// }

// export function MetricUniqueUsersMTD() {
//   const { status, cumChartData, name, categories } = useUniqueUserCountDaily();
//   if (status === "loading") {
//     return <div className="h-full w-full animate-pulse rounded bg-gray-200" />;;
//   }
//   if (status === "failed") {
//     return <div></div>;
//   }
//   if (status === 'idle') {
//     return <div></div>;
//   }
//   const thisMonth = chartByDateOfMonthCategorizer(new UTCDateMini());
//   const thisDateIdx = new UTCDateMini().getDate() -1;
//   return (
//     <Card className="h-48">
//       <Metric className="h-full flex flex-col justify-center items-center">
//         <Title>{name}</Title>
//         {cumChartData[thisDateIdx][thisMonth]}
//         <span className="text-xs">{thisMonth}</span>
//       </Metric>
//     </Card>
//   );
// }

// TODO(liamvdv)
export function MetricTimeInBot() {
  return (
    <Card className="h-48">
      <Metric className="flex h-full flex-col items-center justify-center">
        <Title>Avg. Time in Bot</Title>
        <Text className="text-4xl text-emerald-600">45s</Text>
      </Metric>
    </Card>
  );
}

// TODO(liamvdv)
export function MetricBubbleOpenRate() {
  return (
    <Card className="h-48">
      <Metric className="flex h-full flex-col items-center justify-center">
        <Title>Bubble Open Rate</Title>
        <Text className="text-4xl text-emerald-600">20.34%</Text>
      </Metric>
    </Card>
  );
}

function useBubbleLauncherFunnelQuery() {
  const { projectId } = useParams();
  const since = startOfDay(subDays(new UTCDateMini(), 92));
  const until = endOfDay(new UTCDateMini());
  return {
    metricName: "BubbleLauncherFunnel",
    interval: "1d",
    since: since.toISOString(),
    until: until.toISOString(),
    project: [Number(projectId)],
  };
}

export function ChartBubbleLauncherFunnel() {
  const [vizMode, setVizMode] = useState<string>("count");
  const query = useBubbleLauncherFunnelQuery();
  const { metric, status } = useMetric(query);
  if (status === "failed") {
    return <div></div>;
  }
  if (status !== "succeeded") {
    return <div className="h-full w-full animate-pulse rounded bg-gray-200" />;
  }
  const funnelData = metric.result.map((o: any) => ({
    name: o.name,
    value: o.count,
  }));

  const funnelDataPercentage = funnelData.map(
    ({ name, value }: { name: string; value: number }) => ({
      name,
      value: Math.round((value / funnelData[0].value) * 10000) / 100,
    })
  );

  return (
    <Card>
      <div className="flex items-center justify-between">
        <Title>Bubble Launcher Funnel</Title>
        <Select
          className="w-24"
          enableClear={false}
          value={vizMode}
          onValueChange={setVizMode}
          disabled={funnelData[0].value === 0}
        >
          <SelectItem value="count">Count</SelectItem>
          <SelectItem value="percentage">Percent</SelectItem>
        </Select>
      </div>
      <Flex className="mt-4">
        <Text>
          <Bold>Stage</Bold>
        </Text>
        <Text>
          <Bold>{vizMode == "count" ? "Count" : "Percent"}</Bold>
        </Text>
      </Flex>
      <BarList
        data={vizMode == "count" ? funnelData : funnelDataPercentage}
        className="mt-2"
      />
      {funnelData[0].value === 0 && (
        <div className="mt-4 text-sm text-gray-700">
          Only available if you use v2 of the bubble embed launcher.
        </div>
      )}
      {/* {metric.result[1]} */}
    </Card>
  );
}

function getMessageCountQueryDaily() {
  const since = startOfDay(subDays(new UTCDateMini(), 92));
  const until = endOfDay(new UTCDateMini());
  return {
    metricName: "MessageCount",
    interval: "1d",
    since: since.toISOString(), // 2021-01-01T00:00:00.sssZ
    until: until.toISOString(),
  };
}

function getMessageCountQueryHourly() {
  const since = startOfDay(subDays(new UTCDateMini(), 31));
  const until = endOfDay(new UTCDateMini());
  return {
    metricName: "MessageCount",
    interval: "1h",
    since: since.toISOString(), // 2021-01-01T00:00:00.sssZ
    until: until.toISOString(),
  };
}

// NOTE(liamvdv): function does not depend on UTC, but the date-fns library will likely be used on downstream elements, so we use UTCDateMini
const mergeDateSeries = (
  series1: { timestamp: UTCDateMini; [key: string]: any }[],
  series2: { timestamp: UTCDateMini; [key: string]: any }[]
) => {
  const result: { timestamp: UTCDateMini; [key: string]: any }[] = [];
  let i = 0,
    j = 0;

  while (i < series1.length || j < series2.length) {
    if (
      j >= series2.length ||
      (i < series1.length &&
        series1[i].timestamp.getTime() < series2[j].timestamp.getTime())
    ) {
      result.push(series1[i++]);
    } else if (
      i >= series1.length ||
      series1[i].timestamp.getTime() > series2[j].timestamp.getTime()
    ) {
      result.push(series2[j++]);
    } else {
      result.push({ ...series1[i], ...series2[j] });
      i++;
      j++;
    }
  }

  return result;
};

function sliceDateSeries(
  series: { timestamp: Date; [key: string]: any }[],
  since: Date,
  until: Date
) {
  return series.filter(
    (item) =>
      item.timestamp.getTime() >= since.getTime() &&
      item.timestamp.getTime() <= until.getTime()
  );
}

function chartByDateOfMonthCategorizer(date: Date) {
  return date.toLocaleString("en-US", {
    month: "short",
    year: "numeric",
    timeZone: "UTC",
  });
}

function chartByDayOfMonth(
  canonicalSeries: { timestamp: Date; count: number }[],
  since: Date,
  until: Date
) {
  const slice = sliceDateSeries(canonicalSeries, since, until);
  log("slice", slice);
  const daySeries: { day: number; [key: string]: number }[] = Array.from(
    { length: 31 },
    (_, i) => ({ day: i + 1 })
  );
  slice.forEach((row) => {
    const day = row.timestamp.getUTCDate();
    const monthAndYear = chartByDateOfMonthCategorizer(row.timestamp);
    if (row.count !== undefined) {
      daySeries[day - 1][monthAndYear] = row.count;
    }
  });
  return daySeries;
}

function getRowsFromMetric(metric: any) {
  // map column to row
  const rows = metric.data.map(
    (row: string | number[]) =>
      metric.columns.reduce((o: any, k: string, i: number) => {
        return { ...o, [k]: row[i] };
      }, {}) as Record<string, number | string>
  );
  return rows;
}

function useMessageCountHourly() {
  const query = getMessageCountQueryHourly();
  const { metric, status } = useMetric(query);
  log("useMetric Hook Result", metric);
  if (status !== "succeeded") {
    return { canonicalMessageCount: [], metric, status };
  }
  const rows = getRowsFromMetric(metric);
  const canonicalRows = rows.map(
    ({
      StartTimestamp,
      MessageCount,
    }: {
      StartTimestamp: string;
      MessageCount: number;
    }) => ({ timestamp: new UTCDateMini(StartTimestamp), count: MessageCount })
  );

  const canonicalSeries = eachHourOfInterval({
    start: new UTCDateMini(query.since),
    end: new UTCDateMini(query.until),
  }).map((d) => ({ timestamp: new UTCDateMini(d) }));
  const canonicalMessageCount = mergeDateSeries(canonicalSeries, canonicalRows);
  log("canonicalMessageCount", canonicalMessageCount);

  return { canonicalMessageCount, metric, status };
}

function chartByHour(
  canonicalSeries: { timestamp: Date; count: number }[],
  since: Date,
  until: Date
) {
  const slice = sliceDateSeries(canonicalSeries, since, until);
  const hours = Array.from({ length: 24 }, () => 0);
  slice.forEach(({ timestamp, count }) => {
    const hour = timestamp.getUTCHours();
    hours[hour] += count ?? 0;
  });
  const chartData = hours.map((count, hour) => ({ hour, messages: count }));
  return chartData;
}
// UTC TIME CORRECT
function useMessageCountDaily() {
  const query = getMessageCountQueryDaily();
  const { metric, status } = useMetric(query);
  // log("useMetric Hook Result", metric);
  if (status !== "succeeded") {
    return { canonicalMessageCount: [], metric, status };
  }

  // map column to row
  const rows = getRowsFromMetric(metric);

  // columns are StartTimestamp, MessageCount
  // canonical means the Date object is parsed from the timestamp
  const canonicalRows = rows.map(
    ({
      StartTimestamp,
      MessageCount,
    }: {
      StartTimestamp: string;
      MessageCount: number;
    }) => ({ timestamp: new UTCDateMini(StartTimestamp), count: MessageCount })
  );

  const canonicalSeries = eachDayOfInterval({
    start: new UTCDateMini(query.since),
    end: new UTCDateMini(query.until),
  }).map((d) => ({ timestamp: new UTCDateMini(d) }));
  // log(
  //   "canonicalSeries (expect HH:MM to 00:00)",
  //   canonicalSeries.map((d) => d.timestamp.toISOString())
  // );

  const canonicalMessageCount = mergeDateSeries(canonicalSeries, canonicalRows);
  // log("canonicalMessageCount", canonicalMessageCount);

  return { canonicalMessageCount, metric, status };
}

function startOfLastNMonths(base: Date, n: number) {
  return Array.from({ length: n }, (_, i) => startOfMonth(subMonths(base, i)));
}

function accumulateChartData(
  series: { [key: string]: number | string }[],
  categories: string[],
  xAxisKey: string
) {
  const sums = categories.reduce(
    (acc, c) => ({ ...acc, [c]: 0 }),
    {} as Record<string, number>
  );

  return series.map((entry) => {
    const { [xAxisKey]: xAxisValue, ...categoryMapping } = entry;
    // validate that all category values are numbers for below code.
    Object.values(categoryMapping).forEach((v) => {
      if (Number.isNaN(v)) throw new Error("Expected number");
    });

    // NOTE(liamvdv): For reference: this is the code that only accumulates if the category is present in the series
    //                and does not store the count in subsequent xAxis entries.
    //     const pairs = Object.entries(categoryMapping).map(([category, count]) => {
    //       sums[category] += count as number;
    //       return [category, sums[category]];
    //     });
    const pairs = categories.map((category) => {
      const count = categoryMapping[category] || 0;
      sums[category] += count as number;
      return [category, sums[category]];
    });
    return { [xAxisKey]: xAxisValue, ...Object.fromEntries(pairs) };
  });
}

function useMessageCountByDate() {
  const { canonicalMessageCount, status } = useMessageCountDaily();
  if (status !== "succeeded") {
    return { status };
  }

  const [m0, m1, m2] = startOfLastNMonths(new UTCDateMini(), 3);
  log("m0", m0, "m1", m1, "m2", m2);
  const categories = [m0, m1, m2].map((date) =>
    chartByDateOfMonthCategorizer(date)
  );
  const chartData = chartByDayOfMonth(
    canonicalMessageCount as { timestamp: Date; count: number }[],
    m2,
    endOfDay(new UTCDateMini())
  );
  log("chartData", chartData);

  const cumChartData = accumulateChartData(chartData, categories, "day");
  log("chartData (accMode)", chartData);

  return { status, chartData, cumChartData, categories };
}

export function ChartMessageCountToday() {
  const { status, canonicalMessageCount } = useMessageCountHourly();
  if (status !== "succeeded") {
    return <div>Loading</div>;
  }

  const since = startOfDay(new Date());
  const until = endOfDay(new Date());
  const chartData = chartByHour(
    canonicalMessageCount as { timestamp: Date; count: number }[],
    since,
    until
  );

  const anyDataPoint = chartData.some((row) => row.messages > 0);
  return (
    <Card>
      <Title>Messages Today</Title>
      <BarChart
        className="mt-6"
        data={anyDataPoint ? chartData : []}
        index="hour"
        categories={["messages"]}
        colors={["emerald"]}
        yAxisWidth={40}
        noDataText="No messages today"
      />
    </Card>
  );
}

export function MetricMessageCountToday() {
  const { status, chartData, categories } = useMessageCountByDate();
  if (status !== "succeeded") {
    return <div>Loading</div>;
  }
  const today = chartData[new UTCDateMini().getDate() - 1];
  return (
    <Card>
      <Metric className="h-full">
        <div className="flex h-full flex-col items-center justify-center">
          <Text>Questions Answered Today</Text>
          {today[categories[0]] || 0}
        </div>
      </Metric>
    </Card>
  );
}
function getDeltaType(percentage: number) {
  if (percentage < -0.6) return "decrease";
  if (percentage >= -0.6 && percentage < -0.2) return "moderateDecrease";
  if (percentage >= -0.2 && percentage <= 0.2) return "unchanged";
  if (percentage > 0.2 && percentage <= 0.6) return "moderateIncrease";
  if (percentage > 0.6) return "increase";
}

export function MetricMessageCountMTD() {
  const { status, cumChartData, categories } = useMessageCountByDate();
  if (status !== "succeeded") {
    return <div>Loading</div>;
  }
  const thisMonth = categories[0];
  const lastMonth = categories[1];
  const mtd = cumChartData[new UTCDateMini().getDate() - 1];
  // formally wrong, but intuitively 100% (all messages new) communicates well.
  const delta = mtd[lastMonth] === 0 ? 1 : mtd[thisMonth] / mtd[lastMonth] - 1;
  const formattedDelta = delta.toLocaleString("en-US", { style: "percent" });
  const deltaType = getDeltaType(delta);
  log(mtd, delta, formattedDelta, deltaType);

  return (
    <Card>
      <Metric className="h-full">
        <div className="flex h-full flex-col items-center justify-center">
          <Text>Questions Answered {categories[0]} (MTD)</Text>
          {mtd[categories[0]] || 0}
          <BadgeDelta
            className="absolute bottom-7 sm:right-0 md:right-8 lg:right-14 "
            deltaType={deltaType}
            tooltip="Percentages throughout the day will vary, since last months MTD refers to the whole day, while today only upto the current hour."
          >
            {formattedDelta} MoM
          </BadgeDelta>
        </div>
      </Metric>
    </Card>
  );
}

// a single day can appear multiple times in the metric because we have two dimensions (length, count)
function useConversationLength() {
  const query = getConversationLength();
  const { metric, status } = useMetric(query);
  log("useConversationLength", metric);
  if (status !== "succeeded") {
    return { canonicalConversationLength: [], metric, status };
  }
  const rows = getRowsFromMetric(metric);
  const canonicalRows = rows.map(
    ({
      StartTimestamp,
      ConversationLength,
      Count,
    }: {
      StartTimestamp: string;
      ConversationLength: number;
      Count: number;
    }) => ({
      timestamp: new UTCDateMini(StartTimestamp),
      length: Math.round(ConversationLength / 2), // TODO(liamvdv): remove /2 when metric returns normal message (message = Question & Answer).
      count: Count,
    })
  );
  const canonicalSeries = eachDayOfInterval({
    start: new UTCDateMini(query.since),
    end: new UTCDateMini(query.until),
  }).map((d) => ({ timestamp: new UTCDateMini(d) }));
  const canonicalConversationLength = mergeDateSeries(
    canonicalSeries,
    canonicalRows
  );
  log("canonicalConversationLength", canonicalConversationLength);
  return { canonicalConversationLength, metric, status, query };
}

function baseDailyQuery(metricName: string) {
  const { since, until } = useMetricSearchParams();
  return {
    metricName,
    interval: "1d",
    since: since.toISOString(), // 2021-01-01T00:00:00.sssZ
    until: until.toISOString(),
  };
}

const getConversationLength = () => baseDailyQuery("ConversationLength");

function useBaseDailyMetric(metricName: string) {
  const query = baseDailyQuery(metricName);
  const { metric, status } = useMetric(query);
  if (status !== "succeeded") {
    return { canonicalMetric: [], metric, status };
  }
  const rows = getRowsFromMetric(metric);
  console.log("rows", rows);
  const canonicalRows = rows.map(
    ({
      StartTimestamp,
      Category,
      Value,
    }: {
      StartTimestamp: string;
      Category: string;
      Value: number;
    }) => ({
      timestamp: new UTCDateMini(StartTimestamp),
      category: Category,
      value: Value,
    })
  );
  const canonicalSeries = eachDayOfInterval({
    start: new UTCDateMini(query.since),
    end: new UTCDateMini(query.until),
  }).map((d) => ({ timestamp: new UTCDateMini(d) }));
  const canonicalMetric = mergeDateSeries(canonicalSeries, canonicalRows);
  return { canonicalMetric, metric, status, query };
}

function splitCamelCase(input: string): string {
  // Split the string at each point a lowercase letter is followed by an uppercase letter
  return input.replace(/([a-z])([A-Z])/g, "$1 $2");
}

// NOTE(liamvdv): Template
// export function DonutChartCategories({ metricName }: { metricName: string }) {
//   const { canonicalMetric, metric, status } = useBaseDailyMetric(metricName);
//   const { filters, filterBy, range, setRange } = useMonthFilters(3, 92);
//   if (status !== "succeeded") {
//     return <div className="h-full w-full animate-pulse rounded bg-gray-200" />;;
//   }

//   const data = aggregateByCategory(
//     canonicalMetric,
//     filterBy.since,
//     filterBy.until
//   );

//   return (
//     <Card>
//       <div className="flex flex-row justify-between">
//         <Title>{splitCamelCase(metricName)}</Title>
//         <SelectRange range={range} setRange={setRange} filters={filters} />
//       </div>
//       <DonutChart
//         className="mt-6"
//         variant="donut"
//         data={data}
//         category="value"
//         index="category"
//         colors={["red-400", "blue-400", "green-400"]}
//         valueFormatter={(value) =>
//           value == 1 ? `${value} message` : `${value} messages`
//         }
//       />
//     </Card>
//   );
// }

export function ChartUserSentiment() {
  const navigate = useNavigate();
  const { project } = useCurrentProject();
  const metricName = "UserSentiment";
  const { canonicalMetric, status, query } = useBaseDailyMetric(metricName);
  if (status !== "succeeded") {
    return <div className="h-full w-full animate-pulse rounded bg-gray-200" />;
  }

  const colorize = (category: string) =>
    category == "Neutral"
      ? "gray-400"
      : category == "Positive"
        ? "green-400"
        : "red-400";
  const data = aggregateByCategory(
    canonicalMetric as {
      value: number;
      category: string;
      timestamp: UTCDateMini;
    }[],
    new UTCDateMini(query.since),
    new UTCDateMini(query.until)
  );

  return (
    <Card>
      <div className="flex flex-row justify-between">
        <Title>{splitCamelCase(metricName)}</Title>
      </div>
      <DonutChart
        className="mt-6"
        variant="donut"
        data={data}
        category="value"
        index="category"
        colors={data.map(({ category }) => colorize(category))}
        valueFormatter={(value) =>
          value == 1 ? `${value} message` : `${value} messages`
        }
        onValueChange={(value) =>
          navigate(
            `/${project?.id}/messages/search?sentiment=${value.category}`
          )
        }
      />
    </Card>
  );
}

export function ChartUserRating() {
  const navigate = useNavigate();
  const { project } = useCurrentProject();
  const metricName = "UserRating";
  const { canonicalMetric, status, query } = useBaseDailyMetric(metricName);

  if (status !== "succeeded") {
    return <div className="h-full w-full animate-pulse rounded bg-gray-200" />;
  }

  const data = aggregateByCategory(
    canonicalMetric as {
      value: number;
      category: string;
      timestamp: UTCDateMini;
    }[],
    new UTCDateMini(query.since),
    new UTCDateMini(query.until)
  );
  const namedData = data.map((obj) => ({
    ...obj,
    name:
      obj.category == "unrated"
        ? "Unrated"
        : obj.category == "thumbsUp"
          ? "Liked"
          : "Disliked",
  }));

  const colorize = (category: string) =>
    category == "unrated"
      ? "gray-400"
      : category == "thumbsUp"
        ? "green-400"
        : "red-400";

  return (
    <Card>
      <div className="flex flex-row justify-between">
        <Title>{splitCamelCase(metricName)}</Title>
      </div>
      <DonutChart
        className="mt-6"
        variant="donut"
        data={namedData}
        category="value"
        index="name"
        colors={namedData.map(({ category }) => colorize(category))}
        valueFormatter={(value) =>
          value == 1 ? `${value} message` : `${value} messages`
        }
        onValueChange={(value) =>
          navigate(`/${project?.id}/messages/search?rating=${value.category}`)
        }
      />
    </Card>
  );
}

export function ChartAnswerCompleteness() {
  const navigate = useNavigate();
  const { project } = useCurrentProject();
  const metricName = "AnswerCompleteness";
  const { canonicalMetric, status, query } = useBaseDailyMetric(metricName);

  if (status !== "succeeded") {
    return <div className="h-full w-full animate-pulse rounded bg-gray-200" />;
  }

  const data = aggregateByCategory(
    canonicalMetric as {
      value: number;
      category: string;
      timestamp: UTCDateMini;
    }[],
    new UTCDateMini(query.since),
    new UTCDateMini(query.until)
  );

  const colorize = (category: string) =>
    category == "Complete"
      ? "green-600"
      : category == "Partial"
        ? "green-400"
        : "red-400";

  return (
    <Card>
      <div className="flex flex-row justify-between">
        <Title>{splitCamelCase(metricName)}</Title>
      </div>
      <DonutChart
        className="mt-6"
        variant="donut"
        data={data}
        category="value"
        index="category"
        colors={data.map(({ category }) => colorize(category))}
        valueFormatter={(value) =>
          value == 1 ? `${value} message` : `${value} messages`
        }
        onValueChange={(value) =>
          navigate(
            `/${project?.id}/messages/search?completeness=${value.category}`
          )
        }
      />
    </Card>
  );
}

function RewriteHrefToReactDomRouter({ children }: { children: ReactNode }) {
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;

    // Find the closest <a> element from the event target
    const anchorElement = target.closest("a");

    if (anchorElement && anchorElement.getAttribute("href")) {
      event.preventDefault();
      const href = anchorElement.getAttribute("href");
      if (href) {
        navigate(href);
      }
    }
  };
  return <div onClick={handleClick}>{children}</div>;
}

export function ChartUserLanguage() {
  const MAX_ITEMS = 7;
  const metricName = "UserLanguage";
  const { project, status: projectStatus } = useCurrentProject();
  const { canonicalMetric, status, query } = useBaseDailyMetric(metricName);

  if (status !== "succeeded" || projectStatus !== "succeeded") {
    return <div className="h-full w-full animate-pulse rounded bg-gray-200" />;
  }

  const data = aggregateByCategory(
    canonicalMetric as {
      value: number;
      category: string;
      timestamp: UTCDateMini;
    }[],
    new UTCDateMini(query.since),
    new UTCDateMini(query.until)
  );
  log("UserLanguage data", data);
  const barListData = data
    .map(({ category, value }) => ({
      name: LANGUAGES_LIST[category]?.name || category,
      value: value,
      href: `/${project?.id}/messages/search?language=${category}`,
    }))
    .sort((a, b) => b.value - a.value);
  const barListDataTrimmed = [
    ...barListData.slice(0, MAX_ITEMS - 1),
    ...(barListData.length > MAX_ITEMS
      ? [
          {
            name: "Other",
            value: barListData
              .slice(MAX_ITEMS - 1)
              .reduce((acc, { value }) => acc + value, 0),
          },
        ]
      : []),
  ];
  return (
    <RewriteHrefToReactDomRouter>
      <Card>
        <div className="flex flex-row justify-between">
          <Title>User Language</Title>
        </div>
        <Flex className="my-4">
          <Text>
            <Bold>Language</Bold>
          </Text>
          <Text>
            <Bold>Message Count</Bold>
          </Text>
        </Flex>
        <BarList data={barListDataTrimmed} />
        {data.length === 0 && (
          <Flex className="my-4 items-center justify-center">
            <Text>No messages yet.</Text>
          </Flex>
        )}
      </Card>
    </RewriteHrefToReactDomRouter>
  );
}

function chartByLength(
  series: {
    count: number;
    length: number;
    timestamp: UTCDateMini;
  }[],
  since: Date,
  until: Date
) {
  const slice = sliceDateSeries(series, since, until);
  const maxLength = Math.max(...slice.map(({ length }) => length ?? 0));
  const chartData = Array.from({ length: 1 + maxLength }, (_, i) => ({
    length: i,
    count: 0,
  }));
  slice
    .filter(({ length, count }) => count !== undefined && length !== undefined)
    .forEach(({ length, count }) => (chartData[length].count += count));
  chartData.shift(); // remove first element (length 0)

  const nConversations = chartData.reduce((acc, { count }) => acc + count, 0);
  const nMessages = chartData.reduce(
    (acc, { count, length }) => acc + count * length,
    0
  );
  const avg = Math.round((nMessages / nConversations) * 100) / 100;
  return { chartData, avg };
}

function aggregateByCategory(
  series: {
    value: number;
    category: string;
    timestamp: UTCDateMini;
  }[],
  since: Date,
  until: Date
) {
  const slice = sliceDateSeries(series, since, until).filter(
    ({ category, value }) => value !== undefined && category !== undefined
  );
  const categories = Array.from(new Set(slice.map(({ category }) => category)));
  const chartData = categories.map((category) => ({
    category,
    value: 0,
  }));
  slice.forEach(({ category, value }) => {
    const idx = categories.indexOf(category);
    chartData[idx].value += value;
  });
  return chartData;
}

export function ChartConversationLength() {
  const { canonicalConversationLength, status, query } =
    useConversationLength();

  if (status !== "succeeded") {
    return <div className="h-full w-full animate-pulse rounded bg-gray-200" />;
  }
  const { chartData, avg } = chartByLength(
    canonicalConversationLength as {
      count: number;
      length: number;
      timestamp: UTCDateMini;
    }[],
    new UTCDateMini(query.since),
    new UTCDateMini(query.until)
  );

  return (
    <Card className="h-full">
      <div className="flex flex-row justify-between">
        <Title>Conversation Length</Title>
      </div>

      <div className="flex flex-row items-center h-full">
        <DonutChart
          variant="donut"
          data={chartData}
          category="count"
          index="length"
          label={`Ø ${avg}`}
          colors={[
            "green-400",
            "green-500",
            "green-600",
            "lime-400",
            "lime-500",
            "lime-600",
            "yellow-400",
            "yellow-500",
            "yellow-600",
            "red-400",
            "red-500",
            "red-600",
          ]}
          valueFormatter={(value) =>
            value == 1 ? `${value} conversation` : `${value} conversations`
          }
        />
      </div>
    </Card>
  );
}

export function MetricConversationCount() {
  const { canonicalConversationLength, status } = useConversationLength();
  if (status !== "succeeded") {
    return <div className="h-full w-full animate-pulse rounded bg-gray-200" />;
  }
  const formatter = (timestamp: Date) =>
    timestamp.toLocaleString("en-US", { month: "short", year: "numeric" });
  const categories = startOfLastNMonths(new UTCDateMini(), 3).map(formatter);
  const sumMonths = canonicalConversationLength.reduce(
    (acc, { timestamp, count }) => ({
      ...acc,
      [formatter(timestamp)]: (acc[formatter(timestamp)] ?? 0) + (count ?? 0),
    }),
    {} as Record<string, number>
  );
  log("sumMonths", sumMonths, categories);
  return (
    <Card>
      <Metric className="h-full">
        <div className="flex h-full flex-col items-center justify-center">
          <Text>Conversations {categories[0]}</Text>
          {sumMonths[categories[0]]}
        </div>
      </Metric>
    </Card>
  );
}
