import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { ClerkProvider } from "@clerk/clerk-react";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import EnsureAuthorized from "./EnsureAuthorized.tsx";
import store from "./store";
import ErrorBoundary from "./components/ErrorBoundary";
import Navigation from "./components/Navigation.tsx";
import ProjectsPage from "./components/Projects.tsx";
import ProjectDashboard from "./components/ProjectDashboardPage.tsx";
import ProjectMetrics from "./components/ProjectMetrics.tsx";
import Conversations from "./components/conversations/ConversationsMetadataPage.tsx";
import ConversationPage from "./components/conversations/ConversationPage.tsx";
import AdminDashboard from "./components/AdminDashboard.tsx";
import DashboardToken from "./components/DashboardToken.tsx";
import TestSuitesPage from "./components/evaluation/TestSuitesPage.tsx";
import TestSuitePage from "./components/evaluation/TestSuitePage.tsx";
import TestSuiteRunPage from "./components/evaluation/TestSuiteRunPage.tsx";
import TestSuiteEditorPage from "./components/evaluation/TestSuiteEditPage.tsx";
import TestSuiteCreatePage from "./components/evaluation/TestSuiteCreatePage.tsx";
import TestCasePage from "./components/evaluation/TestCasePage.tsx";
import TestCaseCreatePage from "./components/evaluation/TestCaseCreatePage.tsx";
import TestCaseEditPage from "./components/evaluation/TestCaseEditPage.tsx";
import TestSuiteRunCreatePage from "./components/evaluation/TestSuiteRunCreatePage.tsx";
import TestSuiteRunsPage from "./components/evaluation/TestSuiteRunsPage.tsx";
import MessageSearchPage from "./components/MessageSearchPage.tsx";
import { setupAnalytics } from "./analytics.ts";
import { setupMonitoring } from "./sentry.ts";
import ProjectSettingsPage from "./components/ProjectSettingsPage.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { configureApiClient } from "./api-config.ts";
import FrameEditorPage from "./components/integration/FrameEditorPage.tsx";
import FrameEditorSelector from "./components/integration/FrameSelectorPage.tsx";
import { Toaster } from "./components/ui/toaster.tsx";
import FrameLocaleGallary from "./misc/FrameLocaleGallary.tsx";

// Import your publishable key
const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;
if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

setupMonitoring();
setupAnalytics();
configureApiClient();

// NOTE(liamvdv): could wrap every route in a React.lazy() load and Suspense.

const router = createBrowserRouter([
  {
    path: "/frame-locale-gallery",
    element: <FrameLocaleGallary />,
  },
  {
    path: "/",
    element: <EnsureAuthorized />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/",
        element: <Navigation />,
        children: [
          { path: "/", element: <ProjectsPage /> },
          {
            path: "/:projectId",
            element: <ProjectDashboard />,
          },
          {
            path: "/:projectId/metrics",
            element: <ProjectMetrics />,
          },
          {
            path: "/:projectId/conversations",
            element: <Conversations />,
          },
          {
            path: "/:projectId/messages/search",
            element: <MessageSearchPage />,
          },
          {
            path: "/:projectId/frames",
            element: <FrameEditorSelector />,
          },
          {
            path: "/:projectId/frames/:frameId",
            element: <FrameEditorPage />,
          },
          {
            path: "/:projectId/conversations/:conversationId",
            element: <ConversationPage />,
          },
          {
            path: "/:projectId/testsuites",
            element: <TestSuitesPage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId",
            element: <TestSuitePage />,
          },
          {
            path: "/:projectId/testsuites/create",
            element: <TestSuiteCreatePage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId/edit",
            element: <TestSuiteEditorPage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId/runs",
            element: <TestSuiteRunsPage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId/runs/create",
            element: <TestSuiteRunCreatePage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId/runs/:testSuiteRunId",
            element: <TestSuiteRunPage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId/testcases/:testCaseId",
            element: <TestCasePage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId/testcases/:testCaseId/edit",
            element: <TestCaseEditPage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId/testcases/create",
            element: <TestCaseCreatePage />,
          },
          {
            path: "/:projectId/settings",
            element: <ProjectSettingsPage />,
          },
          { path: "/token", element: <DashboardToken /> },
          {
            path: "/admin",
            element: <AdminDashboard />,
          },
          {
            path: "/break",
            element: (
              <button
                onClick={() => {
                  throw new Error("Error Monitoring Test");
                }}
              >
                Error Monitoring: Throw Error
              </button>
            ),
          },
        ],
      },
    ],
  },
]);

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition="bottom-left"
        />
      </QueryClientProvider>
    </ClerkProvider>
    <Toaster />
  </React.StrictMode>
);
