import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DateRangePicker, DateRangePickerValue } from "@tremor/react";
import { format } from "date-fns";

function formatDate(date: Date): string {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss'Z'");
}

const QueryDateRangePicker: React.FC<{
  disabled?: boolean;
  prefix?: string;
}> = ({ disabled = false, prefix = "" }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const sinceKey = `${prefix}since`;
  const untilKey = `${prefix}until`;
  const since = searchParams.get(sinceKey) || undefined;
  const until = searchParams.get(untilKey) || undefined;

  // If the user has not selected a date range, we default to the last 30 days.
  const [dateRange, setDateRange] = useState<DateRangePickerValue>({
    // We explicitly set the default date range to ensure the dates exist when
    // searchParams are pushed.
    from: new Date(since || new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
    // Into the future, to include today.
    to: new Date(until || new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
    selectValue: !since && !until ? "t" : undefined,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDateRangeChange = (range: DateRangePickerValue) => {
    // Inclusive until, also needed for single day view.
    if (
      range.to &&
      range.to.getHours() === 0 &&
      range.to.getMinutes() === 0 &&
      range.to.getSeconds() === 0
    ) {
      range.to.setHours(23, 59, 59);
    }
    setDateRange(range);
    if (range.from && range.to) {
      setSearchParams({
        [sinceKey]: formatDate(range.from),
        [untilKey]: formatDate(range.to),
      });
    }
  };

  // push from, until once
  useEffect(() => {
    if (since == undefined && until == undefined) {
      handleDateRangeChange(dateRange);
    }
  }, [since, until, dateRange, handleDateRangeChange]);

  return (
    <DateRangePicker
      disabled={disabled}
      className="w-full"
      value={dateRange}
      onValueChange={handleDateRangeChange}
      enableClear={false}
    />
  );
};

export default QueryDateRangePicker;
