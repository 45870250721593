import { useUser } from "@clerk/clerk-react";
import { useParams } from "react-router-dom";
import { Button } from "./ui/button";
import { Check, Copy } from "lucide-react";
import { useState } from "react";
import { useProject } from "../hooks";

function PreviewUrlButton({
  frameId,
  projectId,
}: {
  frameId: string;
  projectId: number;
}) {
  // get project
  const { project, status } = useProject(projectId);
  const url = `https://preview.botbrains.io/?frameId=${frameId}&target=${project?.website_url || ""}`;

  const [copySuccess, setCopySuccess] = useState("");

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setCopySuccess("Copied!");
      setTimeout(() => setCopySuccess(""), 2000);
    } catch (err) {
      setCopySuccess("Failed to copy!");
      setTimeout(() => setCopySuccess(""), 2000);
    }
  };

  if (status !== "succeeded") return <></>;
  return (
    <Button
      onClick={copyToClipboard}
      variant="outline"
      type="button"
      size="sm"
      className="text-gray-600 hover:text-gray-700"
    >
      {copySuccess ? (
        <Check className="mr-2 h-4 w-4" />
      ) : (
        <Copy className="mr-2 h-4 w-4" />
      )}
      Copy Preview Url
    </Button>
  );
}

export default function PreviewUrl() {
  const { frameId, projectId } = useParams();
  const { user } = useUser();
  const isAdmin = user?.publicMetadata?.sys_admin;
  if (!isAdmin) return <></>;
  else
    return (
      <PreviewUrlButton frameId={frameId!} projectId={Number(projectId)} />
    );
}
