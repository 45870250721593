import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";

interface SlideOverProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const SlideOver: React.FC<SlideOverProps> = ({ isOpen, onClose, children }) => {
  const navBarHeight = 101;
  const [topOffset, setTopOffset] = useState(navBarHeight);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const newOffset = Math.max(0, navBarHeight - scrollTop);
      setTopOffset(newOffset);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <Transition show={isOpen}>
        <aside
          className="relative z-10"
          aria-labelledby="slide-over-title"
          role="dialog"
          aria-modal="true"
          onClick={handleOutsideClick}
        >
          <Transition.Child
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-gray-100 bg-opacity-75 transition-opacity h-[calc(100vh-${topOffset}px)]"
              style={{ top: `${topOffset}px` }}
            />
          </Transition.Child>

          <section
            className={`fixed inset-0 overflow-hidden h-[calc(100vh-${topOffset}px)]`}
            style={{ top: `${topOffset}px` }}
          >
            <div
              className="absolute inset-0 overflow-hidden"
              onClick={handleOutsideClick}
            >
              <div
                className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 h-[calc(100vh-${topOffset}px)]"
                style={{ top: `${topOffset}px` }}
              >
                <Transition.Child
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="pointer-events-auto relative h-full w-full max-w-md">
                    <Transition.Child
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="relative rounded-md text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-[1.5px] focus:ring-gray-600"
                          onClick={onClose}
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <svg
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </Transition.Child>
                    <main className="h-full overflow-y-scroll bg-white shadow-xl">
                      {children}
                    </main>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </section>
        </aside>
      </Transition>
    </>
  );
};

export default SlideOver;
