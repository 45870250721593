import { TestCaseRun } from "@/structs";
import { CheckView, ConversationView } from "./TestCaseViews";

import { Link, useParams } from "react-router-dom";
import { Button } from "../ui/button";
import { useToast } from "../ui/use-toast";
import { useAppDispatch, useTestCase } from "@/hooks";
import { updateTestCase } from "@/slices/evaluations";
import { TestCaseSubmit } from "./TestCaseForm";

export default function TestCaseRunView({
  testCaseRun,
}: {
  testCaseRun: TestCaseRun;
}) {
  const { projectId, testSuiteId } = useParams();

  if (!projectId || !testSuiteId) {
    throw new Error("Logical Error missing params");
  }

  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const { testCase } = useTestCase({
    project_id: Number(projectId),
    testSuiteId,
    testCaseId: testCaseRun.testcase_id,
  });
  const passed = testCaseRun.passed ?? false;
  const classNameLast = passed
    ? "ring-4 ring-inset ring-green-400"
    : "ring-4 ring-inset ring-red-400";

  return (
    <div className="space-y-4 p-4">
      <ConversationView
        conversation={{
          messages: [
            ...testCaseRun.input.messages,
            ...(testCaseRun.output ? [testCaseRun.output] : []),
          ],
        }}
        classNameLast={testCaseRun.output ? classNameLast : ""}
      />
      {testCaseRun.passed === false &&
        testCase &&
        testCase.check.comparator == "SEMANTICALLY_EQUIVALENT" && (
          <div className="flex justify-end">
            <Button
              onClick={() => {
                const handleUpdateTestCase = async (values: TestCaseSubmit) =>
                  await dispatch(updateTestCase(values));

                handleUpdateTestCase({
                  testcase_id: testCase.id,
                  ...testCase,
                  check: {
                    ...testCase.check,
                    // @ts-expect-error expected will always be a string
                    expected: testCaseRun.output!.content,
                  },
                });
                toast({
                  title: "Updated Expected Answer",
                  description:
                    "This will take effect on the next test suite run.",
                });
              }}
            >
              Accept Answer
            </Button>
          </div>
        )}
      <CheckView
        check={testCaseRun.check}
        checkOutput={testCaseRun.check_output ?? undefined}
        isPassed={testCaseRun.passed}
      />
      <div className="flex justify-end">
        <Link to={`./../../testcases/${testCaseRun.testcase_id}/edit`}>
          <Button>Edit Test Case</Button>
        </Link>
      </div>
    </div>
  );
}
