import { HandoffRead, HandoffStatus } from "@/client/types.gen";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import DOMPurify from "dompurify";

const formatHandoffTime = (date: Date) => {
  return new Intl.DateTimeFormat("default", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }).format(new Date(date));
};

const HandoffStatusBadge = ({ status }: { status: HandoffStatus }) => {
  const getStatusColor = (status: HandoffStatus) => {
    switch (status) {
      case "DRAFT":
        return "bg-yellow-100 text-yellow-800 border-yellow-200";
      case "SUBMITTED":
        return "bg-green-100 text-green-800 border-green-200";
      case "CANCELLED":
        return "bg-gray-100 text-gray-800 border-gray-200";
      default:
        return "bg-gray-100 text-gray-800 border-gray-200";
    }
  };

  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border ${getStatusColor(status)}`}
    >
      {status.charAt(0) + status.slice(1).toLowerCase()}
    </span>
  );
};

const HandoffEmailContent = ({ htmlContent }: { htmlContent: string }) => {
  const sanitizeConfig = {
    ALLOWED_TAGS: ["p", "br", "a", "strong", "em", "ul", "ol", "li"],
    ALLOWED_ATTR: ["href", "target", "rel"],
  };

  const sanitizedHtml = DOMPurify.sanitize(htmlContent, sanitizeConfig);

  return (
    <div
      className="prose prose-sm max-w-none text-purple-800"
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
    />
  );
};

const HandoffMessage = ({
  handoff,
  renderDetails = true,
}: {
  handoff: HandoffRead;
  renderDetails?: boolean;
}) => {
  return (
    <div className="flex gap-4">
      {renderDetails && (
        <div className="flex-shrink-0 mt-1 p-1">
          <EnvelopeIcon className="h-6 w-6 text-purple-500" />
        </div>
      )}
      <div className="flex-grow min-w-0">
        <div className="flex items-center gap-2 mb-2 text-sm text-gray-500">
          <span className="font-medium">Handoff</span>
          <HandoffStatusBadge status={handoff.status} />
        </div>

        <div className="relative">
          <div className="relative p-4 rounded-lg shadow-sm bg-purple-50 border border-purple-100">
            <div className="mb-3">
              <h3 className="text-base font-medium text-purple-900">
                {handoff.subject}
              </h3>
            </div>

            <div className="space-y-3 text-sm">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <div className="font-medium text-purple-900">User Email</div>
                  <div className="text-purple-800">
                    {handoff.user_email || "N/A"}
                  </div>
                </div>

                <div>
                  <div className="font-medium text-purple-900">
                    Support Email
                  </div>
                  <div className="text-purple-800">
                    {handoff.support_email || "N/A"}
                  </div>
                </div>
              </div>

              <div>
                <div className="font-medium text-purple-900">Content</div>
                <div className="mt-1 rounded-md bg-purple-100/50 p-2">
                  <HandoffEmailContent htmlContent={handoff.body} />
                </div>
              </div>
            </div>

            {renderDetails && handoff.status !== "DRAFT" && (
              <div className="absolute bottom-0 right-0 translate-y-full pt-1">
                <div className="flex items-center gap-1 text-xs text-gray-500">
                  {handoff.status.charAt(0) +
                    handoff.status.slice(1).toLowerCase()}{" "}
                  at <span>{formatHandoffTime(handoff.updated_at)}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HandoffMessage;
