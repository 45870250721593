import { Layers } from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import { listFramesOptions } from "@/client/@tanstack/react-query.gen";
import { useParams, useNavigate } from "react-router-dom";
import ErrorBox from "../Error";
import { getErrorMessages } from "@/lib/utils";
import Spinner from "../Loading";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

const FrameEditorSelector = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const {
    data: frames,
    error,
    isFetching,
  } = useQuery({
    ...listFramesOptions({
      path: {
        project_id: Number(projectId),
      },
    }),
  });

  if (isFetching) return <Spinner />;
  if (error) {
    return ErrorBox({
      title: "Failed to load frames",
      bullets: getErrorMessages(error),
    });
  }

  const framesList = frames?.data || [];

  if (framesList.length === 0) {
    return ErrorBox({
      title: "No frames found",
      bullets: [
        "No frames are available in this project. Please contact support.",
      ],
    });
  }

  // If there's only one frame, redirect to it
  if (framesList.length === 1) {
    const frameId = String(framesList[0].id);
    navigate(`/${projectId}/frames/${frameId}`);
    return <Spinner />;
  }

  return (
    <div className="min-h-screen space-y-6 p-6">
      <Card className="mx-auto w-full max-w-3xl">
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <Layers className="h-5 w-5" />
            Select Frame
          </CardTitle>
          <CardDescription>
            Choose which frame you want to configure
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Select
            onValueChange={(frameId) => {
              navigate(`/${projectId}/frames/${frameId}`);
            }}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select a frame" />
            </SelectTrigger>
            <SelectContent>
              {framesList.map((frame) => (
                <SelectItem key={frame.id} value={String(frame.id)}>
                  {frame.name || `Frame ${frame.id}`}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </CardContent>
      </Card>
    </div>
  );
};

export default FrameEditorSelector;
