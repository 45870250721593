import { useState } from "react";
import {
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ArrowUpDown, Clock, Calendar, Search, PlayCircle } from "lucide-react";
import { formatDistanceToNowStrict } from "date-fns";
import { Input } from "@/components/ui/input";
import { TestSuiteRun } from "@/structs";
import Progress from "./TestRunProgress";
import { useNavigate, useParams } from "react-router-dom";

const columnHelper = createColumnHelper<TestSuiteRun>();

const columns = [
  columnHelper.accessor((row) => row.status, {
    id: "status",
    header: "Status",
    cell: (status) => {
      const row = status.row;
      const run_status = status.getValue();
      const run_passed = row.original.passed;

      if (run_status === "PENDING") return <Progress status="pending" />;
      if (run_status === "COMPLETED" && run_passed)
        return <Progress status="passed" />;
      if (run_status === "COMPLETED" && run_passed === false)
        return <Progress status="failed" />;
      return <Progress status="error" />;
    },
  }),
  columnHelper.accessor((row) => row.name, {
    id: "name",
    header: "Name",
    cell: (name) => (
      <div>
        <h3 className="font-medium text-gray-900">{name.getValue()}</h3>
        <p className="mt-1 line-clamp-2 text-sm text-gray-500">
          {name.row.original.description}
        </p>
      </div>
    ),
  }),
  columnHelper.accessor((row) => row.created_at, {
    id: "created_at",
    header: ({ column }) => (
      <Button
        variant="ghost"
        className="hover:bg-gray-100"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Time
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </Button>
    ),
    cell: (created_at) => {
      const status = created_at.row.original.status;
      const created_at_date = new Date(created_at.getValue());
      const updated_at_date = new Date(created_at.row.original.updated_at);

      return (
        <div className="space-y-1">
          <div className="flex items-center space-x-2 text-sm text-gray-600">
            <Calendar className="h-4 w-4" />
            <span>
              {formatDistanceToNowStrict(created_at_date, { addSuffix: true })}
            </span>
          </div>
          {status !== "PENDING" && (
            <div className="flex items-center space-x-2 text-sm text-gray-500">
              <Clock className="h-4 w-4" />
              <span>
                {(
                  (updated_at_date.getTime() - created_at_date.getTime()) /
                  1000
                ).toFixed(1)}{" "}
                sec
              </span>
            </div>
          )}
        </div>
      );
    },
  }),
];

interface TestSuiteRunTableProps {
  testSuiteRuns: TestSuiteRun[];
  createButtonEnabled?: boolean;
  pageSize?: number;
}

export default function TestSuiteRunTable({
  testSuiteRuns,
  createButtonEnabled = true,
  pageSize = 5,
}: TestSuiteRunTableProps) {
  const [sorting, setSorting] = useState<SortingState>([
    { id: "created_at", desc: true },
  ]);
  const navigate = useNavigate();
  const { projectId, testSuiteId } = useParams();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  const table = useReactTable({
    data: testSuiteRuns,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      globalFilter: searchQuery,
    },
    initialState: {
      pagination: { pageSize },
    },
  });

  return (
    <Card className="w-full">
      <CardHeader className="border-b border-gray-100">
        <div className="flex items-center justify-between">
          <CardTitle className="text-xl font-semibold text-gray-900">
            Test Suite Runs
          </CardTitle>
          <div className="flex items-center gap-4">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-500" />
              <Input
                placeholder="Search runs..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="pl-9"
              />
            </div>
            <Button
              onClick={() =>
                navigate(`/${projectId}/testsuites/${testSuiteId}/runs/create`)
              }
              disabled={!createButtonEnabled}
              className="bg-blue-500 text-white hover:bg-blue-600"
            >
              <PlayCircle className="h-4 w-4" />
              Create Run
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardContent className="p-0">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="hover:bg-transparent">
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id} className="bg-gray-50/50">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  className="cursor-pointer transition-colors hover:bg-gray-50"
                  onClick={() =>
                    navigate(
                      `/${projectId}/testsuites/${testSuiteId}/runs/${row.original.id}`
                    )
                  }
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No test suite runs found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className="flex items-center justify-end space-x-2 border-t border-gray-100 p-4">
          <div className="flex gap-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              Previous
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              Next
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
