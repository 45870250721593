import {
  useConversationsMetadata,
  useCurrentProject,
  useMediaQuery,
} from "../../hooks";
import Spinner from "../Loading";
import QueryDateRangePicker from "../QueryDateRangePicker";
import { useSearchParams } from "react-router-dom";
import LinkWithQuery from "../LinkWithQuery";
import Empty from "../Empty";
import { lazy, Suspense, useState } from "react";
import { Switch } from "../ui/switch";
import { Label } from "../ui/label";
import QueryDeploymentAliasPicker from "../QueryDeploymentAliasPicker";
const TopicCluster = lazy(() => import("../Topics"));

import { formatDistanceToNowStrict } from "date-fns";
import { MessageSquare } from "lucide-react";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { AxiosErrorBox } from "../Error";

interface ConversationsMetadataListProps {
  projectId: number;
  since: Date;
  until: Date;
  aliases: string[];
}

export function ConversationsMetadataListV2({
  projectId,
  since,
  until,
  aliases,
}: ConversationsMetadataListProps) {
  const {
    data: conversationsMetadata,
    status,
    error,
  } = useConversationsMetadata({
    projectId,
    since,
    until,
    aliases,
  });

  if (status === "pending") {
    return (
      <div className="grid grid-cols-1 gap-4 p-4 sm:grid-cols-2 lg:grid-cols-3 bg-gray-50/50">
        {[...Array(6)].map((_, i) => (
          <Skeleton key={i} className="h-40" />
        ))}
      </div>
    );
  }

  if (status === "error" || error) {
    return <AxiosErrorBox error={error} />;
  }

  if (conversationsMetadata.length === 0) {
    return (
      <div className="mt-12">
        <Empty message="No conversations found." />
      </div>
    );
  }

  const sortedConversations = [...conversationsMetadata].sort(
    (a, b) =>
      new Date(b.first_message_time).getTime() -
      new Date(a.first_message_time).getTime()
  );

  return (
    <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2 lg:grid-cols-3 ">
      {sortedConversations.map((conversation) => (
        <LinkWithQuery
          to={`/${projectId}/conversations/${conversation.id}`}
          key={conversation.id}
          className="no-underline"
        >
          <Card className="h-full transition-all hover:shadow-md">
            <CardHeader className="flex-row items-center justify-between space-y-0 pb-2">
              <div className="flex items-center space-x-2 text-sm text-muted-foreground">
                <time>
                  {formatDistanceToNowStrict(
                    new Date(conversation.first_message_time),
                    { addSuffix: true }
                  )}
                </time>
                <span>•</span>
                <div className="flex items-center space-x-1">
                  <MessageSquare className="h-4 w-4" />
                  <span>
                    {
                      conversation.messages.filter((m) => m.role === "user")
                        .length
                    }
                  </span>
                </div>
              </div>
            </CardHeader>
            <CardContent className="space-y-2">
              <p className="line-clamp-2 text-sm font-medium">
                {conversation.messages[0].text}
              </p>
              <p className="line-clamp-2 text-sm text-muted-foreground">
                {conversation.messages[1].text}
              </p>
            </CardContent>
          </Card>
        </LinkWithQuery>
      ))}
    </div>
  );
}

function ConversationsPage() {
  const { project } = useCurrentProject();
  const [searchParams] = useSearchParams();
  const [isClusterVisualized, setClusterVisualized] = useState(
    searchParams.get("visualize") === "true" || false
  );
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const since = searchParams.get("since");
  const until = searchParams.get("until");

  if (project === undefined) return <Spinner />;
  return (
    <div className="pt-8 ">
      <div className="mx-1 max-w-7xl sm:mx-auto sm:px-6 lg:px-8">
        <header>
          <div className="flex justify-between gap-4">
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
              Conversations
            </h1>
            <div className="flex items-center space-x-2">
              {isDesktop && (
                <>
                  <Switch
                    checked={isClusterVisualized}
                    onCheckedChange={setClusterVisualized}
                  />
                  <Label>Topic AI</Label>
                </>
              )}
            </div>
          </div>
        </header>
        <main>
          <div className="mt-4">
            <div className="flex flex-col justify-between gap-4 sm:flex-row">
              <div className="lg:w-96">
                <QueryDeploymentAliasPicker disabled={isClusterVisualized} />
              </div>
              <QueryDateRangePicker disabled={isClusterVisualized} />
            </div>

            {isClusterVisualized ? (
              <div
                className="w-full"
                // NOTE(memben): This is a hack to make the scatter plot fill the screen, 101px for navbar, 100px __approximation__ for the rest
                style={{ height: `calc(100vh - 101px - 100px)` }}
              >
                <Suspense fallback={<Spinner />}>
                  <TopicCluster projectId={project.id} />
                </Suspense>
              </div>
            ) : (
              since &&
              until && (
                <ConversationsMetadataListV2
                  projectId={project.id}
                  since={new Date(since)}
                  until={new Date(until)}
                  aliases={searchParams.getAll("alias")}
                />
              )
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default ConversationsPage;
