import { useProductionAliases } from "@/hooks";
import { InboxArrowDownIcon } from "@heroicons/react/24/outline";
import { MultiSelect, MultiSelectItem } from "@tremor/react";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

function QueryDeploymentAliasPicker({
  disabled = false,
}: {
  disabled?: boolean;
}) {
  // Empty list is 'All'
  const { projectId } = useParams<{ projectId: string }>();
  const { productionAliases, status } = useProductionAliases({
    project_id: projectId!,
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const [aliasIds, setAliasIds] = useState<string[] | undefined>(() => {
    const aliases = searchParams.getAll("alias");
    return aliases.length > 0 ? aliases : undefined; // to indicate null value to component
  });

  const handleAliasChange = (aliasIds: string[]) => {
    setAliasIds(aliasIds);
    setSearchParams((params) => {
      params.delete("alias");
      aliasIds.forEach((aliasId) => params.append("alias", aliasId));
      return params;
    });
  };

  return (
    <div className="">
      <MultiSelect
        icon={InboxArrowDownIcon}
        placeholderSearch="Select aliases"
        value={aliasIds}
        onValueChange={handleAliasChange}
        placeholder="Across All Aliases"
        className="w-full"
        required={false}
        disabled={
          status !== "succeeded" || productionAliases.length === 0 || disabled
        }
        // error={true}
        // errorMessage="Error message"
      >
        {productionAliases.map((alias) => (
          <MultiSelectItem value={alias.id} key={alias.id}>
            via {alias.description}
          </MultiSelectItem>
        ))}
      </MultiSelect>
    </div>
  );
}

export default QueryDeploymentAliasPicker;
