import React from "react";
import { FlaskConical } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { toast } from "@/components/ui/use-toast";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { useParams } from "react-router-dom";
import { ConversationReadFull, MessageReadFull } from "@/client/types.gen";
import { useAppDispatch, useTestSuites } from "@/hooks";
import { createTestCase, CreateTestCase } from "@/slices/evaluations";
import { unwrapResult } from "@reduxjs/toolkit";
import { encodeParams } from "@/lib/utils";

// Helper component for test suite selection
const TestSuiteSelector = ({
  testSuites,
  onSelect,
}: {
  testSuites: Array<{ id: string; name: string }>;
  onSelect: (suiteId: string) => void;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}) => (
  <PopoverContent className="w-48 p-1">
    <div className="flex flex-col gap-1">
      <div className="px-2 py-1.5 text-sm font-medium text-gray-500">
        Select Test Suite
      </div>
      {testSuites.map((suite) => (
        <Button
          key={suite.id}
          variant="ghost"
          className="w-full justify-start text-sm"
          onClick={() => onSelect(suite.id)}
        >
          {suite.name}
        </Button>
      ))}
    </div>
  </PopoverContent>
);

// Helper function to prepare conversation data
const prepareConversationData = (
  conversation: ConversationReadFull,
  message: MessageReadFull
) => {
  let messageIndex = conversation.messages.findIndex(
    (m) => m.id === message.id
  );

  // assistant will be included in the check
  if (message.role === "assistant") messageIndex--;

  const relevantMessages = conversation.messages.slice(0, messageIndex + 1);

  return {
    messages: relevantMessages.map((msg) => ({
      role: msg.role as "user" | "assistant",
      content: msg.text,
    })),
  };
};

// Main component
const CreateTestCaseButton = ({
  conversation,
  message,
}: {
  conversation: ConversationReadFull;
  message: MessageReadFull;
}) => {
  const [isSelectingTestSuite, setIsSelectingTestSuite] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { projectId } = useParams();

  const { testSuites, status: testSuitesStatus } = useTestSuites({
    project_id: Number(projectId),
  });

  const handleTestCaseCreation = async (testSuiteId: string) => {
    const conversationData = prepareConversationData(conversation, message);

    if (message.role === "assistant") {
      const testCaseData: CreateTestCase = {
        testsuite_id: testSuiteId,
        project_id: message.project_id,
        name: "Regression Test",
        description: "",
        input: conversationData,
        check: {
          comparator: "SEMANTICALLY_EQUIVALENT",
          expected: message.text,
          threshold: 0.8,
        },
      };

      try {
        const actionResult = await dispatch(createTestCase(testCaseData));
        unwrapResult(actionResult);
        toast({
          title: "Test Case Created",
          description: (
            <div className="flex items-center gap-2">
              Success!
              <a
                href={`/${message.project_id}/testsuites/${testSuiteId}/testcases/${actionResult.payload.id}`}
                className="underline text-blue-500 hover:text-blue-600"
              >
                View here
              </a>
            </div>
          ),
        });
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to create test case",
          variant: "destructive",
        });
      }
    } else {
      const urlParams = encodeParams({
        name: "Real Interaction Test",
        input: conversationData,
      });

      navigate(
        `/${message.project_id}/testsuites/${testSuiteId}/testcases/create?${urlParams}`
      );
    }
  };

  const handleClick = () => {
    if (testSuitesStatus === "loading") return;

    if (testSuites?.length === 1) {
      handleTestCaseCreation(testSuites[0].id);
    } else if (testSuites?.length > 1) {
      setIsSelectingTestSuite(true);
    } else {
      toast({
        title: "Error",
        description: "No test suites found. Click here to create one.",
        onClick: () => navigate(`/${projectId}/testsuites/create`),
        variant: "destructive",
      });
    }
  };

  return (
    <Popover open={isSelectingTestSuite} onOpenChange={setIsSelectingTestSuite}>
      <PopoverTrigger asChild>
        <button
          className={`${isSelectingTestSuite ? "visible" : "invisible group-hover:visible"} absolute -right-2 -top-2 bg-blue-500 text-white px-3 py-1.5 rounded-full text-xs font-medium flex items-center gap-1.5 hover:bg-blue-600 shadow-sm transition-colors disabled:opacity-50 disabled:cursor-not-allowed`}
          onClick={handleClick}
          disabled={testSuitesStatus === "loading"}
        >
          <FlaskConical className="h-3.5 w-3.5" />
          Create Test Case
        </button>
      </PopoverTrigger>

      {testSuites && testSuites.length > 1 && (
        <TestSuiteSelector
          testSuites={testSuites}
          onSelect={(suiteId) => {
            setIsSelectingTestSuite(false);
            handleTestCaseCreation(suiteId);
          }}
          open={isSelectingTestSuite}
          onOpenChange={setIsSelectingTestSuite}
        />
      )}
    </Popover>
  );
};

export default CreateTestCaseButton;
