import { UserCircleIcon } from "@heroicons/react/24/solid";
import { CubeTransparentIcon } from "@heroicons/react/24/outline";
import ReactMarkdown from "react-markdown";
import { ConversationReadFull, MessageReadFull } from "@/client/types.gen";
import { Tags } from "./MessageTags";
import HandoffMessage from "./Handoff";
import CreateTestCaseButton from "./CreateTestCaseButton";

const formatMessageTime = (date: Date) => {
  return new Intl.DateTimeFormat("default", {
    hour: "numeric",
    minute: "numeric",
  }).format(new Date(date));
};

const MessageHeader = ({
  role,
  user_name,
}: {
  role: string;
  user_name: string;
}) => (
  <div className="flex items-center gap-2 mb-2 text-sm text-gray-500">
    <span className="font-medium">
      {role === "assistant" ? "Assistant" : user_name}
    </span>
  </div>
);

const MessageContent = ({
  message,
  conversation,
  renderDetails,
}: {
  message: MessageReadFull;
  conversation: ConversationReadFull;
  renderDetails: boolean;
}) => {
  const isAssistant = message.role === "assistant";

  return (
    <div
      className={`
      relative p-4 rounded-lg shadow-sm
      ${
        isAssistant
          ? "bg-white border border-gray-200"
          : "bg-blue-50 border border-blue-100"
      }
    `}
    >
      <CreateTestCaseButton conversation={conversation} message={message} />
      <div className="prose prose-sm max-w-none dark:prose-invert">
        {isAssistant ? (
          <ReactMarkdown
            className="text-gray-700"
            components={{
              p: ({ children }) => <>{children}</>,
            }}
          >
            {message.text}
          </ReactMarkdown>
        ) : (
          <div className="whitespace-pre-wrap text-gray-900">
            {message.text}
          </div>
        )}
      </div>
      {message.tags && renderDetails && (
        <div
          className={`mt-3 pt-3 border-t ${isAssistant ? "border-gray-100" : "border-blue-100"}`}
        >
          <Tags tags={message.tags} />
        </div>
      )}
    </div>
  );
};

export const Message = ({
  message,
  conversation,
  showHeader = false,
  renderDetails = true,
  user_name,
  isHighlighted = false,
  messageRef,
}: {
  message: MessageReadFull;
  conversation: ConversationReadFull;
  showHeader?: boolean;
  renderDetails?: boolean;
  user_name: string;
  isHighlighted?: boolean;
  messageRef?: (el: HTMLDivElement | null) => void;
}) => {
  return (
    <>
      <div
        ref={messageRef}
        className={`
        relative group
        ${isHighlighted ? "ring-2 ring-blue-200 rounded-lg" : ""}
      `}
      >
        <div className="flex gap-4">
          {renderDetails && (
            <div className="flex-shrink-0 mt-1">
              {message.role === "user" ? (
                <UserCircleIcon className="h-8 w-8 text-blue-500" />
              ) : (
                <CubeTransparentIcon className="h-8 w-8 text-blue-600" />
              )}
            </div>
          )}
          <div className="flex-grow min-w-0">
            {showHeader && (
              <MessageHeader role={message.role} user_name={user_name} />
            )}
            <div className="relative">
              <MessageContent
                message={message}
                conversation={conversation}
                renderDetails={renderDetails}
              />
              {renderDetails && (
                <div className="w-full flex justify-end mt-1 text-xs text-gray-500">
                  <span>{formatMessageTime(message.created_at)}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {message.handoff && (
        <HandoffMessage
          handoff={message.handoff}
          renderDetails={renderDetails}
        />
      )}
    </>
  );
};
