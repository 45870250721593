import { Path, PathValue, useFormContext } from "react-hook-form";
import { ColorPicker } from "@/components/ui/color-picker";
import { FormLabel } from "./ui/form";

type ColorFieldPath<T> = Path<T> & string;

export const ColorField = <TFormValues extends Record<string, any>>({
  label,
  name,
  className,
}: {
  label: string;
  name: ColorFieldPath<TFormValues>;
  className?: string;
}) => {
  const { setValue, watch } = useFormContext<TFormValues>();
  const value = watch(name);

  return (
    <div className={`mb-4 space-y-2 ${className}`}>
      <div className="flex items-center justify-between">
        <FormLabel className="text-sm font-medium text-gray-700">
          {label}
        </FormLabel>
        <ColorPicker
          value={value}
          onChange={(color) =>
            setValue(name, color as PathValue<TFormValues, typeof name>, {
              shouldDirty: true,
            })
          }
          className="h-7 w-7"
        />
      </div>
    </div>
  );
};
