import { useCurrentProject } from "../hooks";
import {
  ChartAnswerCompleteness,
  ChartBubbleLauncherFunnel,
  ChartConversationLength,
  ChartUserLanguage,
  ChartUserRating,
  ChartUserSentiment,
  MetricTimeInBot,
} from "./Charts";
import ComingSoon from "./ComingSoon";
import Spinner from "./Loading";
import { Button, Grid } from "@tremor/react";
import QueryDeploymentAliasPicker from "./QueryDeploymentAliasPicker";
import {
  WeeklyMessagesHeatmap,
  YearlyMessagesHeatmap,
} from "./charts/Heatmaps";
import QueryDateRangePicker from "./QueryDateRangePicker";
import { TripletMetricsCard } from "./charts/MetricCards";

export default function ProjectMetrics() {
  const { project } = useCurrentProject();
  if (project === undefined) return <Spinner />;
  return (
    <div className="pt-8 bg-gray-50/50">
      <div className="mx-1 max-w-7xl sm:mx-auto sm:px-6 lg:px-8">
        <header className="mb-4">
          <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
            Metrics
          </h1>
          <div className="mt-2 flex flex-col md:flex-row justify-between gap-4">
            <div className="w-full sm:w-[384px]">
              <QueryDeploymentAliasPicker />
            </div>
            <QueryDateRangePicker prefix={"metrics_"} />
          </div>
        </header>
        <main>
          {/* <div className="pt-6"></div>
          <h1 className="text-2xl font-medium pt-2">Messages</h1> */}
          <div className="space-y-6">
            <TripletMetricsCard lastDays={undefined} />
            <YearlyMessagesHeatmap />
            <Grid numItemsMd={2} numItemsLg={3} className="mt-6 gap-6">
              <ChartAnswerCompleteness />
              <ChartUserSentiment />
              <ChartUserRating />
            </Grid>
            <WeeklyMessagesHeatmap />
            <Grid numItemsMd={1} numItemsLg={2} className="gap-6">
              <ChartUserLanguage />
              <ChartConversationLength />
            </Grid>
          </div>

          {/* Only needed while Section in Preview */}
          <header>
            <h1 className="mt-10 text-xl font-bold leading-tight tracking-tight text-gray-900">
              Launcher
            </h1>
          </header>
          <Grid numItemsMd={2} numItemsLg={2} className="mt-6 gap-6">
            <ChartBubbleLauncherFunnel />
          </Grid>
          <ComingSoon hideCover={false}>
            <Grid numItems={2} className="mt-6">
              <MetricTimeInBot />
            </Grid>
          </ComingSoon>

          <Button
            className="mb-24 mt-6 rounded-lg py-2 text-white bg-emerald-600 hover:bg-emerald-700 border-0"
            onClick={() => window.$crisp.push(["do", "chat:open"])}
          >
            Request Metric
          </Button>
        </main>
      </div>
    </div>
  );
}
