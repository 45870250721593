import { useMediaQuery, useTestSuites } from "../../hooks";

import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../Loading";
import { Button } from "../ui/button";

const TestSuitesPage = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { testSuites, status, errorMessage } = useTestSuites({
    project_id: Number(projectId),
  });

  const isDesktop = useMediaQuery("(min-width: 1024px)"); // 1024px == lg

  if (status === "idle") return <Spinner />;
  if (status === "loading") return <Spinner />;
  if (status === "failed") return <div>Error: {errorMessage}</div>;

  if (isDesktop) {
    if (testSuites.length > 0) {
      navigate(`/${projectId}/testsuites/${testSuites[0].id}`);
    }
  }

  return (
    <div className="flex h-[calc(100vh-101px)] w-full flex-row items-center justify-center">
      <Button onClick={() => navigate(`/${projectId}/testsuites/create`)}>
        Create Your First Test Suite
      </Button>
    </div>
  );
};

export default TestSuitesPage;
