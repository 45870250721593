import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  fetchProjects,
  selectProjects,
  selectProjectsStatus,
} from "../slices/projects.ts";
import Spinner from "./Loading";
import LinkWithQuery from "./LinkWithQuery.tsx";
import { Link } from "react-router-dom";

const colors: string[] = [
  "#0284c7",
  "#0d9488",
  "#16a34a",
  "#1d4ed8",
  "#3182ce",
  "#7c3aed",
  "#c026d3",
  "#d97706",
  "#dc2626",
  "#dd6b20",
  "#e11d48",
];

function getColorForString(id: number): string {
  const colorIndex = id % colors.length;
  return colors[colorIndex];
}

function NoProjects() {
  return (
    <div className="text-center">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-semibold text-gray-900">No projects</h3>
      <p className="mt-1 text-sm text-gray-500">
        Make sure you have selected the correct organization.
      </p>
    </div>
  );
}

function Projects() {
  const dispatch = useAppDispatch();
  const projects = useAppSelector(selectProjects);
  const status = useAppSelector(selectProjectsStatus);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchProjects());
    }
  }, [status, dispatch]);

  if (status === "loading") return <Spinner />;
  if (status === "failed")
    return (
      <div className="flex flex-col items-center justify-center text-gray-700 gap-2">
        <p>We could not fetch projects.</p>
        <p>
          Please check the{" "}
          <Link
            className="underline text-indigo-800"
            to="https://status.botbrains.io"
          >
            System Health
          </Link>{" "}
          and retry later.
        </p>
        <p>
          If this problem persists, please reach out to us at{" "}
          <Link
            to="mailto:info@botbrains.io"
            className="underline text-indigo-800"
          >
            info@botbrains.io
          </Link>
          .
        </p>
        <p>Your organization setup might not be complete.</p>
      </div>
    );
  if (projects.length === 0) return <NoProjects />;
  return (
    <ul
      role="list"
      className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4"
    >
      {[...projects].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()).map((project) => (
        <li
          key={project.id}
          className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white overflow-hidden border border-gray-200 hover:shadow-lg hover:ring"
        >
          <div className="bg-white rounded-lg shadow h-full">
            <LinkWithQuery to={`/${project.id}`}>
              <div
                className="w-full h-48 flex items-center justify-center text-white"
                style={{ background: getColorForString(project.id) }}
              >
                {project.name}
              </div>
              <div className="p-5">
                <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
                  {project.name}
                </h5>
                <p className="mb-3 font-normal text-gray-700">
                  {project.description}
                </p>
              </div>
            </LinkWithQuery>
          </div>
        </li>
      ))}
    </ul>
  );
}

export default function ProjectsPage() {
  return (
    <div className="pt-8 ">
      <div className="mx-1 sm:mx-auto max-w-7xl sm:px-6 lg:px-8">
        <header>
          <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
            Projects
          </h1>
        </header>
        <main>
          <div className="pt-6 pb-6">
            <Projects />
          </div>
        </main>
      </div>
    </div>
  );
}
