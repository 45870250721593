export default function CenteredLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <main className="mx-1 max-w-2xl pt-6 sm:mx-auto sm:px-6 lg:px-8">
      <div className="max-w-2xl">{children}</div>
    </main>
  );
}
