import { useNavigate, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useTestCasesByTestSuite,
  useTestSuites,
} from "../../hooks";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { CheckView, ConversationView } from "./TestCaseViews";
import { useState } from "react";
import { deleteTestCase } from "@/slices/evaluations";
import { unwrapResult } from "@reduxjs/toolkit";
import Spinner from "../Loading";
import WithTestSuiteSidebar from "./WithTestSuiteSidebar";
import { EvaluationBreadcrumbsNavbar } from "../BreadcrumbNav";

function TestCaseDropdownMenu({
  projectId,
  testSuiteId,
  testCaseId,
}: {
  projectId: number;
  testSuiteId: string;
  testCaseId: string;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  async function dispatchDelete() {
    setIsDeleting(true);
    try {
      const actionResult = await dispatch(
        deleteTestCase({ project_id: projectId, testcase_id: testCaseId }),
      );
      unwrapResult(actionResult);
      navigate(`/${projectId}/testsuites`);
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setIsDeleting(false);
    }
  }

  if (isDeleting) {
    return <Spinner />;
  }

  if (errorMessage) {
    return <p className="text-red-500">{errorMessage}</p>;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <EllipsisVerticalIcon className="h-6 w-6 "></EllipsisVerticalIcon>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>Test Case</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={() =>
            navigate(
              `/${projectId}/testsuites/${testSuiteId}/testcases/${testCaseId}/edit`,
            )
          }
        >
          Edit
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            if (window.confirm("Do you want to continue")) {
              dispatchDelete();
              {
                projectId;
                testCaseId;
              }
            }
          }}
        >
          {/* <ConfirmDialog
              buttonLabel="Delete"
              dialogTitle={`Warning! You are about to delete Test Suite ${testSuiteName}`}
              dialogDescription="You cannot undo this action. Are you sure you want to continue?"
              
            /> */}
          Delete
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function TestCasePage() {
  const { projectId, testSuiteId, testCaseId } = useParams<{
    projectId: string;
    testSuiteId: string;
    testCaseId: string;
  }>();
  if (projectId == undefined)
    throw new Error("Logical Error: projectId is undefined");
  if (testSuiteId == undefined)
    throw new Error("Logical Error: testSuiteId is undefined");
  if (testCaseId == undefined)
    throw new Error("Logical Error: testCaseId is undefined");

  const {
    testCases,
    status: testCasesStatus,
    errorMessage: testCasesErrorMessage,
  } = useTestCasesByTestSuite({ project_id: Number(projectId), testSuiteId });

  const testCase = testCases.find((testCase) => testCase.id == testCaseId);

  const { testSuites } = useTestSuites({
    project_id: Number(projectId),
  });

  const testSuite = testSuites.find((ts) => ts.id == testSuiteId);

  if (testCasesStatus == "loading") return <p>Loading...</p>;
  if (testCasesStatus == "failed")
    return <p className="text-red-500">Error: {testCasesErrorMessage}</p>;
  if (testCase == undefined) return <p>Test Case not found</p>;

  return (
    <WithTestSuiteSidebar
      projectId={Number(projectId)}
      selectedTestSuiteId={testSuiteId}
    >
      <EvaluationBreadcrumbsNavbar
        items={[
          { href: "./../../..", label: "Test Suites" },
          {
            href: "./../..",
            label: testSuite?.name ?? "Test Suite",
          },
          {
            label: "Test Cases",
          },
          {
            label: testCase.name,
          },
        ]}
      />
      <div className="flex items-center justify-between">
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
          {testCase.name}
        </h1>
        <TestCaseDropdownMenu
          projectId={Number(projectId)}
          testSuiteId={testSuiteId}
          testCaseId={testCaseId}
        />
      </div>
      <p className="mt-2 min-h-[1rem]">{testCase.description}</p>
      <ConversationView conversation={testCase.input} />
      <CheckView check={testCase.check} isPassed={undefined} />
    </WithTestSuiteSidebar>
  );
}

export default TestCasePage;
