import { getErrorMessages } from "@/lib/utils";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { AxiosError } from "axios";
import { HTTPValidationError } from "../client/types.gen";

interface AlertProps {
  title: string;
  bullets: string[];
}

export function AxiosErrorBox({
  error,
}: {
  error: AxiosError<HTTPValidationError, any>;
}) {
  return ErrorBox({
    title: "Failed to load.",
    bullets: getErrorMessages(error),
  });
}

export function ErrorAlert({ title, bullets }: AlertProps) {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{title}</h3>
          {bullets.length > 0 && (
            <div className="mt-2 text-sm text-red-700">
              <ul role="list" className="list-disc space-y-1 pl-5">
                {bullets.map((bullet) => (
                  <li>{bullet}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default function ErrorBox({ title, bullets }: AlertProps) {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{title}</h3>
          {bullets.length > 0 && (
            <div className="mt-2 text-sm text-red-700">
              <ul role="list" className="list-disc space-y-1 pl-5">
                {bullets.map((bullet) => (
                  <li>{bullet}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
